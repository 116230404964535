import React, {useState} from "react";
import {TextField} from "@mui/material";
import {IToolsCard, ToolsCard} from "./toolsCard";
import {useAppSelector} from "../../utils/redux/store";
import {AUTHORITIES, hasAnyAuthority} from "../../AppRoutes";
import {useTranslation} from "react-i18next";
import lessonPlanSvg from "../../assets/tools/lessonplan.jpeg"
import conselingPlan from "../../assets/tools/conselingplan.jpeg"
import ActivityIdea from "../../assets/tools/activityidea.png"
import TransformContent from "../../assets/tools/transformcontent.png"
import SlideGenerator from "../../assets/tools/slidecreator.png"
import QuestionGenerator from "../../assets/tools/questiongenerator.jpeg"
import Roleplay from "../../assets/tools/roleplay.jpeg"
import Teacherbot from "../../assets/tools/teacherbot.jpeg"
import QuickCourseBot from "../../assets/tools/quickcourse.png"

export const Tools = () => {
    const {t} = useTranslation()
    const tools: IToolsCard[] = [
        {
            icon: lessonPlanSvg,
            link: "/tools/lesson-plan-generator",
            title: t('sidebar.LessonPlans'),
            desc: t('sidebar.LessonPlansDesc'),
            isComingSoon: false
        },
        {
            icon: conselingPlan,
            link: "/tools/counseling-generator",
            title: "Counseling advice",
            desc: "Create a Counseling plan for teachers.",
            isComingSoon: false
        },
        {
            icon: ActivityIdea,
            link: "/tools/activity-ideas-generator",
            title: "Activity Ideas",
            desc: "Create an activity ideas plan.",
            isComingSoon: false
        },
        {
            icon: SlideGenerator,
            link: "/tools/slide-generator",
            title: "Summarize",
            desc: "Generate bullet points.",
            isComingSoon: false
        },
        {
            icon: QuestionGenerator,
            link: "/tools/question-generator",
            title: "Question extractor",
            desc: "Generate questions",
            isComingSoon: true
        },
        {
            icon: Roleplay,
            link: "/tools/roleplay-generator",
            title: "Role Play",
            desc: "Practice skills with ai",
            isComingSoon: true
        },
        {
            icon: TransformContent,
            link: "/tools/transform-content-generator",
            title: "Transform Content",
            desc: "Deep Fake future.",
            isComingSoon: false
        },
        // {
        //     icon: Teacherbot,
        //     link: "/tools/teacher-bot",
        //     title: "Teacher bot",
        //     desc: "coming soon",
        //     isComingSoon: true
        // },
        {
            icon: QuickCourseBot,
            link: "/tools/quick-course-bot",
            title: "Quick Course bot",
            desc: "coming soon",
            isComingSoon: true
        }
    ]
    const [search, setSearch] = useState<string>("")
    const isAdmin = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.ADMIN]));


    if (isAdmin) {
        return <h1>Welcome to admin panel</h1>
    } else {
        return (
            <div className="row mx-0 col-12">
                <div className="col-12 px-4">
                    <TextField
                        className="col-12 col-md-5 col-lg-4"
                        placeholder={t('Search your tool')}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </div>
                <div className="row mx-0 col-12 ">
                    {tools.filter(
                        (tool) => tool.title.toLowerCase().includes(search.toLowerCase())
                    ).map((tool) => {
                        return <ToolsCard icon={tool.icon} link={tool.link} title={tool.title} desc={tool.desc}
                                          isComingSoon={tool.isComingSoon}/>
                    })}
                </div>
            </div>
        )
    }
}
