import React, {useEffect} from "react";
import {Button, Card, Skeleton, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {t} from "i18next";
import {reset} from "../../utils/redux/store/promptSlice";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {getMostToolsUsed} from "../../utils/redux/store/adminSlice";
import {BarChart} from "@mui/x-charts";
import {IMostUsedTools} from "../../utils/redux/model/mostUsedTools.model";

export const MostToolsUsed = () => {
    const dispatch = useAppDispatch();
    const entities: IMostUsedTools[] = useAppSelector(state => state.admin.entities);
    const loading: boolean = useAppSelector(state => state.prompt.loading);
    useEffect(() => {
        dispatch(getMostToolsUsed())
        return () => {
            dispatch(reset())
        }
    }, []);
    console.log(entities)
    return (
        <React.Fragment>
            <div className="row mx-0">
                {/* @ts-ignore*/}
                <Button
                    LinkComponent={Link}
                    to="/tools"
                    variant="contained"
                    color="secondary"
                    className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                    startIcon={<IoArrowBack/>}
                >
                    {t('Back')}
                </Button>
                <Card variant="elevation" className="row mx-0 col-11 col-lg-9 mx-auto p-4 mt-5">
                    <div className="col-12 text-center mt-3">
                        <Typography variant="h3">{t('admin.mostToolsUsed')}</Typography>
                    </div>
                    {entities.length === 0 ?
                        <h1>No Data</h1>
                        :
                        <div className="col-12 text-center mt-3">
                            <BarChart
                                xAxis={[{
                                    scaleType: 'band', data: entities.map(x => {
                                        return x.apiName
                                    })
                                }]}
                                series={[{data: entities.map(x => x.count ?? null)}]}
                                height={300}
                            />
                        </div>}
                    {loading &&
                        <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5 h-25">
                            <Skeleton className="row mx-0 col-7 mx-auto p-4 mt-5">
                            </Skeleton>
                        </Card>
                    }

                </Card>
            </div>
        </React.Fragment>
    )
}
