import React, {useState} from 'react';
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    Collapse
} from '@mui/material';
import {FaHome, FaLock, FaCode, FaLightbulb} from 'react-icons/fa';
import {TiThMenu} from "react-icons/ti";
import {MdExpandLess, MdExpandMore} from "react-icons/md";

const drawerWidth = 240;

// @ts-ignore
const Sidebar = ({mobileOpen, handleDrawerToggle}) => {
    const [openSections, setOpenSections] = useState<any>({});

    const handleSectionClick = (index: number) => {
        setOpenSections((prevOpenSections: any[]) => ({
            ...prevOpenSections,
            [index]: !prevOpenSections[index],
        }));
    };

    const sections = [
        {text: 'Introduction', icon: <FaHome/>, href: '#Introduction'},
        {
            text: 'Authentication',
            icon: <FaLock/>,
            nested: [
                {text: 'API Keys', href: '#apikeys'},
                {text: 'OAuth2', href: '#oauth2'},
            ],
        },
        {
            text: 'Endpoints',
            icon: <FaCode/>,
            nested: [
                {text: 'Users', href: '#users'},
                {text: 'Courses', href: '#courses'},
                {text: 'Assignments', href: '#assignments'},
            ],
        },
        {text: 'Examples', icon: <FaLightbulb/>, href: '#section4'},
    ];

    const drawer = (
        <Box sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2}}>
                Teacher tools API Docs
            </Typography>
            <List>
                {sections.map((section, index) => (
                    <React.Fragment key={index}>
                        <ListItem button onClick={() => handleSectionClick(index)} component="a"
                                  href={section.href ? section.href : undefined}>
                            <ListItemIcon>{section.icon}</ListItemIcon>
                            <ListItemText primary={section.text}/>
                            {section.nested ? (
                                openSections[index] ? <MdExpandLess/> : <MdExpandMore/>
                            ) : null}
                        </ListItem>
                        {section.nested && (
                            <Collapse in={openSections[index]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {section.nested.map((nestedItem, nestedIndex) => (
                                        <ListItem button key={nestedIndex} sx={{pl: 4}} component="a"
                                                  href={nestedItem.href}>
                                            <ListItemText primary={nestedItem.text}/>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            {/* Mobile Drawer */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
            >
                {drawer}
            </Drawer>
            {/* Permanent Drawer */}
            <Drawer
                variant="permanent"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
                open
            >
                {drawer}
            </Drawer>
        </>
    );
};

const Content = () => {
    return (
        <Box
            component="main"
            sx={{
                flex: '0 0 auto',
                p: 3,
                width: {sm: `calc(100% - ${drawerWidth}px)`},
            }}
        >
            <Toolbar/>
            <Typography className="scroll-margin-top-100" variant="h4" gutterBottom id="Introduction">
                Introduction
            </Typography>
            <Typography paragraph>
                <p>Welcome to the API documentation. This section provides an overview...</p>
            </Typography>

            <Typography variant="h4" gutterBottom id="section2">
                Authentication
            </Typography>
            <Typography paragraph>
                Details on how to authenticate with our API...
            </Typography>

            <Typography variant="h4" gutterBottom id="section3">
                Endpoints
            </Typography>
            <Typography paragraph>
                A list of API endpoints available for use...
            </Typography>

            <Typography variant="h4" gutterBottom id="section4">
                Examples
            </Typography>
            <Typography paragraph>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>
                <p>Example API calls and responses...</p>

            </Typography>
        </Box>
    );
};

const B2BComponent = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                variant="elevation"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <TiThMenu/>
                    </IconButton>
                    <div className="fw-bold">
                        API Documentation
                    </div>
                </Toolbar>
            </AppBar>
            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>
            <Box
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                }}>

            </Box>
            <Content/>
        </Box>
    );
};

export default B2BComponent;
