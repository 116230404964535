import React from 'react';
import LandingHeader from "./LandingHeader";
import {Avatar, AvatarGroup, Button, Paper, Rating, Typography} from "@mui/material";
import ToolCardLanding from "./ToolCardLanding";
import Carousel from "react-material-ui-carousel";
import {IToolsCard} from "../tools/toolsCard";
import lessonPlanSvg from "../../assets/tools/lessonplan.jpeg";
import conselingPlan from "../../assets/tools/conselingplan.jpeg";
import ActivityIdea from "../../assets/tools/activityidea.png";
import SlideGenerator from "../../assets/tools/slidecreator.png";
import QuestionGenerator from "../../assets/tools/questiongenerator.jpeg";
import Roleplay from "../../assets/tools/roleplay.jpeg";
import TransformContent from "../../assets/tools/transformcontent.png";
import Teacherbot from "../../assets/tools/teacherbot.jpeg";
import QuickCourseBot from "../../assets/tools/quickcourse.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import profile1 from "../../assets/profile/profile1.png";
import profile2 from "../../assets/profile/profile2.png";
import profile3 from "../../assets/profile/profile3.png";
import {IoMdArrowRoundForward} from "react-icons/io";
import LandingBenefitCard from "./LandingBenefitCard";
import LandingBenefitCard1 from "../../assets/LandingBenefitCard/LandingBenefitCard1.svg";
import LandingBenefitCard2 from "../../assets/LandingBenefitCard/LandingBenefitCard2.svg";
import LandingBenefitCard3 from "../../assets/LandingBenefitCard/LandingBenefitCard3.svg";

// Helper function to chunk array into groups of 4
const chunkArray = (array: IToolsCard[], size: number) => {
    return array.reduce((acc, _, i) => {
        if (i % size === 0) {
            acc.push(array.slice(i, i + size));
        }
        return acc;
    }, [] as IToolsCard[][]);
};

const Landing = () => {
    const {t} = useTranslation();

    const tools: IToolsCard[] = [
        {
            icon: lessonPlanSvg,
            link: "/tools/lesson-plan-generator",
            title: t('sidebar.LessonPlans'),
            desc: t('sidebar.LessonPlansDesc'),
            isComingSoon: false
        },
        {
            icon: conselingPlan,
            link: "/tools/counseling-generator",
            title: "Counseling advice",
            desc: "Create a Counseling plan for teachers.",
            isComingSoon: false
        },
        {
            icon: ActivityIdea,
            link: "/tools/activity-ideas-generator",
            title: "Activity Ideas",
            desc: "Create an activity ideas plan.",
            isComingSoon: false
        },
        {
            icon: SlideGenerator,
            link: "/tools/slide-generator",
            title: "Summarize",
            desc: "Generate bullet points.",
            isComingSoon: false
        },
        {
            icon: QuestionGenerator,
            link: "/tools/question-generator",
            title: "Question extractor",
            desc: "Generate questions",
            isComingSoon: true
        },
        {
            icon: Roleplay,
            link: "/tools/roleplay-generator",
            title: "Role Play",
            desc: "Practice skills with ai",
            isComingSoon: true
        },
        {
            icon: TransformContent,
            link: "/tools/transform-content-generator",
            title: "Transform Content",
            desc: "Deep Fake future.",
            isComingSoon: false
        },
        {
            icon: Teacherbot,
            link: "/tools/teacher-bot",
            title: "Teacher bot",
            desc: "Description coming soon",
            isComingSoon: true
        },
        {
            icon: QuickCourseBot,
            link: "/tools/quick-course-bot",
            title: "Quick Course bot",
            desc: "Description coming soon",
            isComingSoon: true
        }
    ];

    const chunkedTools = chunkArray(tools, 6); // Split the tools into groups of 4

    return (
        <div className="container-fluid px-0 mb-5">
            <LandingHeader/>
            <div className="col-12 mt-4">
                <Typography className="text-center" variant="h1">
                    Ahura Ed Tools
                </Typography>
                <Typography className="text-center mt-3" variant="h2">
                    Simplify Teaching and Learning using Ahura
                </Typography>
            </div>
            <div className="row mx-0 mt-2">
                <Carousel indicators={false} duration={5}>
                    {chunkedTools.map((chunk, index) => (
                        <Paper key={index} className="row mx-0 bg-unset border-0 shadow-none" component={Link} to="/Login">
                            {chunk.map((tool) => (
                                <div className="col-12 col-md-6 col-lg-3 col-xl-2 mt-4" key={tool.link}>
                                    <ToolCardLanding title={tool.title} desc={tool.desc} icon={tool.icon}/>
                                </div>
                            ))}
                        </Paper>
                    ))}
                </Carousel>
                <div className="row mx-0 col-12 col-md-10 col-lg-10 mx-auto mt-5">
                    <div className="col-6 col-md-2 col-lg-1">
                        <AvatarGroup>
                            <Avatar alt="Remy Sharp" src={profile1}/>
                            <Avatar alt="Remy Sharp" src={profile2}/>
                            <Avatar alt="Remy Sharp" src={profile3}/>
                        </AvatarGroup>
                    </div>
                    <div className="col-6 col-md-2 col-lg-1">
                        <Typography className="fs-4" variant="h2">2,291</Typography>
                        <Typography variant="caption">Happy Users</Typography>
                    </div>
                    <div className="col-6 col-md-2 col-lg-1 mt-3 mt-md-0">
                        <Typography className="fs-4" variant="h2">4.8/5</Typography>
                        <div className="d-flex mt-1">
                            <Rating value={4.5} precision={0.5} size="small" readOnly/>
                            <Typography variant="caption">Rating</Typography>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-9 text-end align-self-center text-truncate mt-3 mt-md-0">
                        <Button variant="contained" color="warning"
                                onClick={() => window.location.href = "/login"}
                                endIcon={<IoMdArrowRoundForward/>}
                        >
                            Start Learning Now
                        </Button>
                    </div>
                </div>
                <div className="row mx-0 col-12 text-center mt-5">
                    <Typography className="fw-bold fs-3" variant="h3">
                        Features for a better experience
                    </Typography>
                </div>
                <div className="row mx-0 col-12 col-lg-12 mx-auto mt-5">
                    <div className="col-12 col-md-6 col-lg-4">
                        <LandingBenefitCard
                            title="Save time"
                            description="Streamline your workflow and reclaim precious hours"
                            image={LandingBenefitCard1}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0 mt-lg-0 mt-xl-0">
                        <LandingBenefitCard
                            title="Level up Your Teaching Skills"
                            description="Use AI to boost the quality of your teaching materials"
                            image={LandingBenefitCard2}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mt-3 mt-md-3 mt-lg-0 mt-xl-0">
                        <LandingBenefitCard
                            title="Maximise Customization"
                            description="Create a Personallised experience tailored to student's need"
                            image={LandingBenefitCard3}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;
