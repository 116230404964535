import React from 'react';
import {Button} from "@mui/material";
import ahuraLogo from "../../assets/ahuraLogo.svg";

const LandingHeader = () => {
    return (
        <div className="row mx-0 col-12 align-content-between m-3 p-3 bg-white">
            <div className="col align-self-center">
                <img className="ahuraHeaderLogo" src={ahuraLogo} alt="ahuraLogo"/>
            </div>
            <div className="row mx-0 col" dir="rtl">
                <div className="d-flex gap-2">
                    <Button onClick={() => window.location.href = "/Register"}
                            className="col-8 col-md-4 col-lg-3 col-xl-2" variant="contained" color="primary">Sign Up</Button>
                    <Button onClick={() => window.location.href = "/Login"}
                            className="col-8 col-md-4 col-lg-3 col-xl-2 hi-50" variant="contained" color="secondary">Login</Button>
                </div>
            </div>
        </div>
    );
};

export default LandingHeader;
