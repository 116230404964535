import React, {useEffect, useRef, useState} from 'react'
import {
    Box,
    Button,
    Card, CircularProgress, Divider, IconButton, MenuItem, Modal,
    Paper, Select, SelectChangeEvent,
    Skeleton,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Tooltip,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {
    bulkInviteToOrganizationAPI,
    deleteInviteeAPI, editInviteeEmailAPI,
    getAllInvitationListAPI,
    getUnAssignedUserSubscriptionByOrganizationIdAPI,
    inviteToOrganizationAPI, ReAssignUserSubscriptionToInviteeIdAPI,
    revokeInviteeSubscriptionAPI,
    subscribeToPlanBulkForOrgAPI
} from "../../utils/redux/store/organizationSlice";
import {reset} from "../../utils/redux/store/organizationSlice";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {useTranslation} from "react-i18next";
import {IOrganization} from "../../utils/redux/model/organization.model";
import {FaFileCsv, FaPlus} from "react-icons/fa";
import {convertLinuxDateToDate} from "../../utils/convertLinuxDateToDate";
import {toast} from "react-toastify";
import {IInviteeSubscription} from "../../utils/redux/model/inviteeSubscription.model";
import {getEntities} from "../../utils/redux/store/subscriptionPlanSlice";
import {ISubscriptionPlan} from "../../utils/redux/model/subscriptionPlan.model";
import {MdInfo, MdPayment, MdRefresh} from "react-icons/md";
import BuyBulkSubscriptionModal from "./buyBulkSubscriptionModal";
import {GrDocumentCsv} from "react-icons/gr";
import Papa from 'papaparse';

export const InviteTeachers = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [csvData, setCsvData] = useState<any>([]);
    const fileInputRef: any = useRef(null);
    const loading: boolean = useAppSelector(state => state.organization.loading);
    const loadingEntities: boolean = useAppSelector(state => state.organization.loading);
    const entities: IOrganization[] = useAppSelector(state => state.organization.entities);
    const [selectedRow, setSelectedRow] = useState<IOrganization>({} as IOrganization)
    const [isModalEditOpen, setIsModalEditOpen] = useState<boolean>(false)
    const [editEmail, setEditEmail] = useState<string>("")
    const [subscriptionIdModal, setSubscriptionIdModal] = useState<string>("")
    const [isModalAssignOpen, setIsModalAssignOpen] = useState<boolean>(false)
    const loadingUnAssignedUserSubscription: boolean = useAppSelector(state => state.organization.loadingUnAssignedUserSubscription);
    const entityUnAssignedUserSubscription: [] = useAppSelector(state => state.organization.entityUnAssignedUserSubscription);
    const paymentUrl: string = useAppSelector(state => state.organization.paymentUrl);
    const subscriptionPlans: ISubscriptionPlan[] = useAppSelector(state => state.subscriptionPlan.entities);
    const [reqData, setReqData] = useState({invitee_email: ""})
    const [inviteesSubscription, setInviteesSubscription] = useState<IInviteeSubscription[]>([])
    const [finalSubscriptionPrice, setFinalSubscriptionPrice] = useState<number>(0)
    // Use useRef to track the previous state of inviteesSubscription
    const prevInviteesSubscription = useRef<IInviteeSubscription[]>([]);
    useEffect(() => {
        dispatch(getAllInvitationListAPI())
        dispatch(getEntities())
        dispatch(getUnAssignedUserSubscriptionByOrganizationIdAPI())
        return () => {
            dispatch(reset())
        }
    }, []);
    useEffect(() => {
        const finalUpdate: IInviteeSubscription[] = []
        if (entities.length) {
            entities?.filter(x => x.currentSubscriptionId == null)?.map((result: any) => {
                finalUpdate.push({
                    inviteeId: result.id,
                    subscriptionId: result.currentSubscriptionId
                })
            })
            setInviteesSubscription(finalUpdate)
        }

    }, [loadingEntities]);
    useEffect(() => {
        // Calculate finalSubscriptionPrice only if subscriptionId changed
        let totalPrice = finalSubscriptionPrice;

        inviteesSubscription.forEach((invitee, index) => {
            const prevInvitee = prevInviteesSubscription.current[index];
            const hasSubscriptionChanged = !prevInvitee || invitee.subscriptionId !== prevInvitee.subscriptionId;

            if (hasSubscriptionChanged) {
                if (invitee.subscriptionId == 3) {
                    const subscriptionPrice = invitee.subscriptionId
                        ? subscriptionPlans.find(x => x.id === prevInvitee.subscriptionId)?.price || 0
                        : 0;
                    totalPrice -= subscriptionPrice;
                } else if (prevInviteesSubscription.current[index]?.subscriptionId !== null && prevInviteesSubscription.current[index]?.subscriptionId !== invitee?.subscriptionId) {
                    let subscriptionPrice = invitee.subscriptionId
                        ? subscriptionPlans.find(x => x.id === prevInviteesSubscription.current[index]?.subscriptionId)?.price || 0
                        : 0;
                    totalPrice -= subscriptionPrice;
                    subscriptionPrice = invitee.subscriptionId
                        ? subscriptionPlans.find(x => x.id === invitee.subscriptionId)?.price || 0
                        : 0;
                    totalPrice += subscriptionPrice;
                } else {
                    const subscriptionPrice = invitee.subscriptionId
                        ? subscriptionPlans.find(x => x.id === invitee.subscriptionId)?.price || 0
                        : 0;
                    totalPrice += subscriptionPrice;
                }

            }
        });

        // Update the total price
        setFinalSubscriptionPrice(totalPrice);

        // Update the ref to store the current inviteesSubscription as the previous state
        prevInviteesSubscription.current = [...inviteesSubscription];
    }, [inviteesSubscription]);
    const handleAddInvite = () => {
        if (reqData.invitee_email === "") {
            toast.warning("Please add am email.")
            return
        }
        dispatch(inviteToOrganizationAPI(reqData)).finally(() => {
            dispatch(getAllInvitationListAPI())
        })
        setReqData({...reqData, invitee_email: ""})
    }
    const handlePaySubscriptions = () => {
        if (finalSubscriptionPrice === 0) {
            toast.warning("Please choose a subscription plan.")
            return
        }
        dispatch(subscribeToPlanBulkForOrgAPI(inviteesSubscription))
            .then((res) => {
                const response = (res.payload as any).data
                if ((res.payload as any).status === 200) {
                    if (typeof response === "string") {
                        window.location.href = response
                    }
                }
            })
    }
    const handleRefresh = () => {
        dispatch(getAllInvitationListAPI())
        dispatch(getEntities())
        dispatch(getUnAssignedUserSubscriptionByOrganizationIdAPI())
    }
    const handleRevokeInvitation = (inviteeId: number) => {
        dispatch(revokeInviteeSubscriptionAPI({invitee_id: inviteeId})).finally(() => {
            handleRefresh()
        })
    }
    const handleDeleteInvitation = (inviteeId: number) => {
        dispatch(deleteInviteeAPI({invitee_id: inviteeId})).finally(() => {
            handleRefresh()
        })
    }
    const handleEditInvitation = () => {
        if (selectedRow.id == undefined) {
            toast.warning("Please choose an invitee plan.")
            return
        }
        if (editEmail == "") {
            toast.warning("Please fill a new email.")
            return
        }

        dispatch(editInviteeEmailAPI({invitee_id: selectedRow.id!, email: editEmail})).finally(() => {
            handleRefresh()
            setIsModalEditOpen(false)
        })
    }
    const handleReAssignInvitation = () => {
        if (selectedRow.id == undefined) {
            toast.warning("Please choose an invitee plan.")
            return
        }
        if (subscriptionIdModal == "") {
            toast.warning("Please choose an invitee plan.")
            return
        }
        dispatch(ReAssignUserSubscriptionToInviteeIdAPI({inviteeId: selectedRow.id!, userSubscriptionId: subscriptionIdModal})).finally(() => {
            handleRefresh()
            setIsModalAssignOpen(false)
        })
    }
    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const text = e.target.result;
                Papa.parse(text, {
                    header: false,
                    complete: async (result: any) => {
                        setCsvData(result.data);
                        const final: [] = result.data
                        final.map((data) => {
                            dispatch(inviteToOrganizationAPI({invitee_email: data[0]})).finally(() => {
                                dispatch(getAllInvitationListAPI())
                            })
                            new Promise((resolve) => setTimeout(resolve, 4000));
                        })

                        setReqData({...reqData, invitee_email: ""})
                    },
                    error: (error) => {
                        toast.warning("Error parsing CSV")
                        console.error('Error parsing CSV:', error);
                    }
                });
            };
            reader.readAsText(file);
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input click
        }
    };

    if (loading && loadingEntities && inviteesSubscription.length === 0 && loadingUnAssignedUserSubscription) {
        return (
            <Card variant="elevation" className="row mx-0 col-12 mx-auto p-4 h-25">
                <Skeleton className="row mx-0 col-12 mx-auto p-4 mt-5">
                    <CircularProgress/>
                </Skeleton>
            </Card>
        )
    }
    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>


            <Card variant="elevation" className="row mx-0 col-12 col-lg-12 mx-auto p-4 mt-5">
                <div className="col-12">
                    <Typography variant="h5">You have {entityUnAssignedUserSubscription?.length} active subscription ready to assign to an invitee</Typography>
                    <Typography>{subscriptionPlans.find(x => x.id == 1)?.name} plan : {entityUnAssignedUserSubscription?.filter((x: any) => x.subscription_plan_id == 1)?.length}</Typography>
                    <Typography>{subscriptionPlans.find(x => x.id == 2)?.name} plan :
                        {entityUnAssignedUserSubscription?.filter((x: any) => x.subscription_plan_id == 2)?.length}</Typography>
                </div>
                <div className="col-12">
                    <BuyBulkSubscriptionModal/>
                </div>
                <Divider className="mt-4 mb-2"/>
                <div className="col-12 col-md-4 col-lg-3 position-relative mt-3">
                    <Typography variant="subtitle1">{t('login.email')}:</Typography>
                    <TextField
                        className="col-12"
                        type="email"
                        placeholder={t('login.emailPlaceholder')}
                        value={reqData.invitee_email}
                        onChange={event => setReqData({...reqData, invitee_email: event.target.value})}
                    />
                </div>
                <div className="col-6 col-md-4 d-flex gap-4  align-items-center px-0">
                    <Button variant="text" color='primary' className="gap-0 mt-4 px-3"
                            startIcon={<FaPlus/>}
                            onClick={handleAddInvite}
                    >
                        {t('Add')}
                    </Button>
                    <div>
                        <input
                            ref={fileInputRef} // Link the file input to the ref
                            type="file"
                            accept=".csv"
                            style={{display: 'none'}} // Hide the input element
                            onChange={handleFileUpload}
                        />
                        <Button
                            variant="text"
                            color="primary"
                            className="gap-0 mt-4 px-3"
                            startIcon={<GrDocumentCsv/>}
                            onClick={handleButtonClick} // Call the function to trigger input click
                        >
                            {t('Import CSV')}
                        </Button>

                        {/*/!* Render CSV data for testing (optional) *!/*/}
                        {/*{csvData.length > 0 && (*/}
                        {/*    <div>*/}
                        {/*        <h3>{t('Parsed CSV Data')}</h3>*/}
                        {/*        <pre>{JSON.stringify(csvData.map((x:any)=>x), null, 2)}</pre>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                    <Button variant="text" color='primary' className="gap-0 mt-4 px-3"
                            startIcon={<MdRefresh size={24}/>}
                            onClick={handleRefresh}
                    >
                        {t('refresh')}
                    </Button>
                </div>
                <div className="col-12 col-md-5 text-end align-items-center px-2">
                    <Button variant="contained" color='primary' className="gap-0 mt-4 px-3"
                            startIcon={<MdPayment/>}
                            disabled={loading}
                            onClick={handlePaySubscriptions}
                    >
                        {loading ? <CircularProgress/> : t('Final Payment')} : ${finalSubscriptionPrice}
                    </Button>
                </div>
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('organization.InviteTeachers')}</Typography>
                </div>
                {/*{JSON.stringify(inviteesSubscription)}*/}
                {entities && entities.length > 0 ?
                    <TableContainer className="mt-3" component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="fw-bold">ID</TableCell>
                                    <TableCell className="fw-bold">invitee email</TableCell>
                                    <TableCell className="fw-bold">status</TableCell>
                                    <TableCell className="fw-bold">License</TableCell>
                                    <TableCell className="fw-bold">Subscription Expire Date</TableCell>
                                    <TableCell className="fw-bold">Invited Date</TableCell>
                                    <TableCell className="fw-bold text-center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entities?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell>{row.invitee_email}</TableCell>
                                        <TableCell
                                            className={row.status?.toLowerCase() === "pending" ?
                                                "text-warning fw-bold"
                                                :
                                                row.status?.toLowerCase() === "rejected" ?
                                                    "text-danger fw-bold"
                                                    :
                                                    row.status?.toLowerCase() === "accepted" ?
                                                        "text-success fw-bold" :
                                                        "fw-bold"
                                            }
                                        >
                                            {row.status}
                                        </TableCell>
                                        <TableCell>
                                            {row.currentSubscriptionId !== null ?
                                                <Tooltip
                                                    title={subscriptionPlans.find(x => x.id == row.currentSubscriptionId)?.name + " subscription :  To change this subscription, please wait until the current subscription expires."}>
                                                    <IconButton>
                                                        <MdInfo size={20}/>
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                <Select
                                                    className="col-12"
                                                    labelId="language-select-label"
                                                    id="language-select"
                                                    disabled={row.currentSubscriptionId !== null}
                                                    value={inviteesSubscription.find(x => x.inviteeId == row.id)?.subscriptionId}
                                                    defaultValue={row.currentSubscriptionId == null ? 3 : row.currentSubscriptionId}
                                                    onChange={(e) => {
                                                        const inviteeId = parseInt(String(row.id!)); // assuming you get inviteeId from data attribute
                                                        const subscriptionId = parseInt(e.target.value as string); // new subscriptionId

                                                        setInviteesSubscription((prev) => {
                                                            const existingInvitee = prev.find((invitee) => invitee.inviteeId === inviteeId);
                                                            if (existingInvitee) {
                                                                // Update subscriptionId if invitee exists
                                                                return prev.map((invitee) =>
                                                                    invitee.inviteeId === inviteeId
                                                                        ? {...invitee, subscriptionId}
                                                                        : invitee
                                                                );
                                                            } else {
                                                                // Add new invitee with subscriptionId
                                                                return [...prev, {inviteeId, subscriptionId}];
                                                            }
                                                        });
                                                    }}

                                                    variant="filled"
                                                >
                                                    {subscriptionPlans.map((subscriptionPlan) => {
                                                        return (
                                                            <MenuItem key={subscriptionPlan.id} value={subscriptionPlan.id}>
                                                                <Box display="flex" alignItems="center">
                                                                    {subscriptionPlan.name}
                                                                </Box>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            }

                                        </TableCell>
                                        <TableCell>{row.currentSubscriptionId === 3 || row.currentSubscriptionId == null ? "Unlimited" : convertLinuxDateToDate(row.currentSubscriptionExpireDate!)}</TableCell>
                                        <TableCell>{convertLinuxDateToDate(row.createdDate!)}</TableCell>
                                        <TableCell className="d-flex text-center gap-1" component="th" scope="row">
                                            {row.currentSubscriptionId != null ? <Tooltip title="Revoke subscription">
                                                    <Button variant="outlined" color="warning" onClick={() => handleRevokeInvitation(row.id!)}>
                                                        Revoke
                                                    </Button>
                                                </Tooltip>
                                                :
                                                entityUnAssignedUserSubscription?.length != 0 &&
                                                <Tooltip title="ReAssign subscription">
                                                    <Button variant="outlined" color="info" onClick={() => {
                                                        setSelectedRow(row)
                                                        setIsModalAssignOpen(true)
                                                    }}>
                                                        Assign
                                                    </Button>
                                                </Tooltip>
                                            }

                                            <Tooltip title="Delete">
                                                <Button variant="outlined" color="error" onClick={() => handleDeleteInvitation(row.id!)}>
                                                    Delete
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <Button variant="contained" color="error" onClick={() => {
                                                    setSelectedRow(row)
                                                    setIsModalEditOpen(true)
                                                }}>
                                                    Edit
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant="h5" className="mt-3">{t("organization.noInvitedUserFound")}</Typography>
                }
            </Card>
            <Modal open={isModalEditOpen}
                   onClose={() => {
                       setIsModalEditOpen(false)
                       setSelectedRow({})
                       setEditEmail("")
                   }}
            >
                <Box className="col-12 col-md-6 col-lg-5 m-auto mt-5 align-content-center">
                    <div className="row mx-0 bg-white rounded-2 p-4 mt-5">
                        <div className="col-12">
                            <Typography variant="h3">Edit </Typography>
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">New email address:</Typography>
                            <TextField
                                className="col-12"
                                type="email"
                                placeholder={"Old Email : " + selectedRow!.invitee_email!}
                                value={editEmail}
                                onChange={event => setEditEmail(event.target.value)}
                            />
                        </div>
                        <div className="col-12 text-end mt-3">
                            <Button className="w-25" variant="contained"
                                    onClick={handleEditInvitation}
                            >Save</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal open={isModalAssignOpen}
                   onClose={() => {
                       setIsModalAssignOpen(false)
                       setSelectedRow({})
                       setSubscriptionIdModal("")
                   }}>
                <Box className="col-12 col-md-6 col-lg-5 m-auto mt-5 align-content-center">
                    <div className="row mx-0 bg-white rounded-2 p-4 mt-5">
                        <div className="col-12">
                            <Typography variant="h3">Edit </Typography>
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">subscription List:</Typography>
                            <Select
                                variant="outlined"
                                value={subscriptionIdModal}
                                onChange={(event: SelectChangeEvent) => setSubscriptionIdModal(event.target.value!)}
                            >
                                {entityUnAssignedUserSubscription?.map((data: any) => {
                                    // return <MenuItem value={data.id}>{JSON.stringify(data)}</MenuItem>
                                    return <MenuItem
                                        value={data.id}>{subscriptionPlans.find(x => x.id == data.subscription_plan_id)?.name + " plan expires in " + convertLinuxDateToDate(data.expirationDate)}</MenuItem>
                                })}

                            </Select>
                        </div>
                        <div className="col-12 text-end mt-3">
                            <Button className="w-25" variant="contained"
                                    onClick={handleReAssignInvitation}
                            >Assign</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
