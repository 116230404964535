// src/components/LanguageSwitcher.js
import React from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItem, Select, FormControl, InputLabel, Box} from '@mui/material';
import {FlagIcon} from 'react-flag-kit';
import {useAppDispatch} from "../../utils/redux/store";
import {changeCurrentLanguageAPI} from "../../utils/redux/store/userSlice";
import {checkValidate} from "../../utils/redux/store/authSlice";

const languages = [
    {code: 'en', name: 'English', country: 'US'},
    {code: 'es', name: 'Español', country: 'ES'},
];

export const LanguageSwitcher = () => {
    const dispatch = useAppDispatch();
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const {t} = useTranslation();

    const handleChange = (event: { target: { value: string | undefined; }; }) => {
        i18n.changeLanguage(event.target.value);
        dispatch(changeCurrentLanguageAPI({language: event.target.value})).finally(() => {
            dispatch(checkValidate())
        })
    };

    return (
        <FormControl fullWidth>
            <Select
                labelId="language-select-label"
                id="language-select"
                value={currentLanguage}
                onChange={handleChange}
                variant="filled"
            >
                {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                        <Box display="flex" alignItems="center">
                            <FlagIcon code={lang.country as any} size={24} style={{marginRight: 8}}/>
                            {lang.name}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
