import {IGuest} from "./guest.model";

export interface IRolePlay {
    id?: number | null,
    guid?: string | null,
    room_name?: string | null,
    user_role?: string | null,
    ai_role?: string | null,
    scenario?: string | null,
    max_guests_count?: number | null,
    grade_level?: string | null
    is_closed?: boolean | null
    guests?: IGuest[] | null
}

export const defaultValue: {} = {};
