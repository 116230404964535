import React, {useState} from 'react';
import {Button, Card, TextField, Typography} from "@mui/material";
import logo from "../../assets/logo.png";
import {t} from "i18next";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../utils/redux/store";
import {resetPasswordAPI} from "../../utils/redux/store/authSlice";
import {useNavigate, useParams} from "react-router-dom";

const ResetPassword = () => {
    const {guid} = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [pass, setPass] = useState("")
    const [rePass, setRePass] = useState("")

    const handleResetPass = () => {
        if (pass === "" || rePass === "") {
            toast.warning("please fill the form.")
            return
        }
        if (pass !== rePass) {
            toast.warning("password and repeat password are not the same!!")
            return;
        }
        dispatch(resetPasswordAPI({guid: String(guid), newPassword: pass})).then((res) => {
            if (res.payload) {
                navigate("/")
            }
        })
    }
    return (
        <div className="vh-100 align-content-center">
            <Card className="col-12 col-lg-3 mx-auto p-3" id='login-page' variant="elevation" autoFocus={false}>
                <div className="col-6 mx-auto">
                    <img className='col-12' src={logo} alt='loginbackground'/>
                </div>

                <div className='row justify-content-center mt-3'>
                    <div className='col-10'>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('login.password')}:</Typography>
                            <TextField
                                className="col-12"
                                type="text"
                                placeholder={t('login.passwordPlaceholder')}
                                onChange={(e) => setPass(e.target.value)}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('login.rePassword')}:</Typography>
                            <TextField
                                className="col-12"
                                type="text"
                                placeholder={t('login.rePasswordPlaceholder')}
                                onChange={(e) => setRePass(e.target.value)}
                            />
                        </div>

                        <div className="col-12 mt-4">
                            <div className="col-12 mt-3 px-0 px-lg-4">
                                <Button className="col-12" variant="contained" color="primary"
                                        onClick={handleResetPass}
                                >
                                    {t('login.ResetPass')}
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>
            </Card>
        </div>

    );
};

export default ResetPassword;
