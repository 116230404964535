import React, {useEffect} from 'react';
import { Typography} from "@mui/material";
import ChatContainer from "./chatContainer";

const RoleplayRoomChat = () => {
    useEffect(() => {
        // Function to dynamically import the CSS file
        const importCss = async () => {
            const cssModule = await require('./chat.css');
            // Create a link element for the stylesheet
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = cssModule.default; // Assuming your CSS file is exported correctly
            document.head.appendChild(link);

            return link; // Return the link element for cleanup
        };

        // Call the import function
        const cssLinkPromise = importCss();

        // Cleanup function to remove the CSS link when the component unmounts
        return () => {
            cssLinkPromise.then(link => {
                if (link) {
                    document.head.removeChild(link);
                }
            });
        };
    }, []); // Empty dependency array means this effect runs once when mounted

    return (
        <div className="row col-12 p-3 py-4 mx-auto mt-5 rounded">
            <div className="col-12 mt-2 text-center">
                <Typography variant="h3">
                    ChatBox
                </Typography>
            </div>
            <div className="col-12 mt-2 ">
                <ChatContainer/>
            </div>
        </div>
    );
};

export default RoleplayRoomChat;

