import React, {useState} from 'react';


import {Button, Modal, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {IoCloseOutline} from 'react-icons/io5';
import {useAppDispatch} from "../../utils/redux/store";
import {logout} from "../../utils/redux/store/authSlice";

export const Logout = (props: { setShowLogoutModal: any }) => {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(true);

    const handleLogout = () => {
        dispatch(logout());
        window.location.href = '/';
    };
    return (
        <Modal open={showModal}>
            <div className='row bg-light p-4 position-absolute modallogout'>
                <div className='col-12 text-end'>
                    <IconButton onClick={() => {
                        setShowModal(false);
                        props.setShowLogoutModal(false);
                    }}>
                        <IoCloseOutline/>
                    </IconButton>
                </div>
                <div className='col-12 mt-4'>
                    <Typography variant='h3'>Are you sure you want to <span
                        style={{color: '#f61114'}}>log out</span> ?</Typography>
                </div>
                <div className='row mx-0 col-12 mt-5 px-0'>
                    <div className='col-6 text-start'>
                        <Button className='col-11 fs-6 px-0' variant='outlined' color='primary'
                                onClick={() => {
                                    setShowModal(false);
                                    props.setShowLogoutModal(false);
                                }}>cancel</Button>
                    </div>
                    <div className='col-6 text-end'>
                        <Button className='col-11 fs-6 px-0' variant='contained' color='primary'
                                onClick={handleLogout}>Yes, log out</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Logout;
