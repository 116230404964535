import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../utils/redux/store";
import {GetListOfGuestsInRoomAPI} from "../../../../utils/redux/store/rolePlaySlice";
import {IGuest} from "../../../../utils/redux/model/guest.model";
import {
    Button,
    IconButton,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from '@mui/material';
import {convertLinuxDateToDate} from "../../../../utils/convertLinuxDateToDate";
import {MdSettingsVoice} from "react-icons/md";
import {t} from "i18next";
import {IoArrowBack} from "react-icons/io5";

const RoleplayGuestsList = () => {
    const dispatch = useAppDispatch()
    const {roomGuid} = useParams()
    const guests: IGuest[] = useAppSelector(state => state.roleplay.entities)
    const [search, setSearch] = useState("")
    useEffect(() => {
        dispatch(GetListOfGuestsInRoomAPI({guid: roomGuid!}))
    }, []);
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (
        <div className="row mx-0">
            <div className="col-12 mb-4">
                {/* @ts-ignore*/}
                <Button
                    LinkComponent={Link}
                    to="/tools/roleplay-generator"
                    variant="contained"
                    color="secondary"
                    className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                    startIcon={<IoArrowBack/>}
                >
                    {t('Back')}
                </Button>
            </div>
            <div className="col-12 mt-5">
                <Typography variant="subtitle1">{t('search')}:</Typography>
                <TextField
                    className="col-12 col-lg-3"
                    placeholder={t('search')}
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
            </div>
            <TableContainer className="mt-4" component={Paper}>
                <Table size="medium" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="fw-bold fs-6">Student Name</TableCell>
                            <TableCell className="fw-bold fs-6" align="center">email</TableCell>
                            <TableCell className="fw-bold fs-6" align="center">score</TableCell>
                            <TableCell className="fw-bold fs-6" align="center">joined at</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {guests.filter((guest) =>
                            guest.name?.toLowerCase().includes(search.toLowerCase()) ||
                            guest.email?.toLowerCase().includes(search.toLowerCase()
                            )).map((guest) => (
                            <StyledTableRow
                                key={guest.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>{guest.name}</TableCell>
                                <TableCell align="center">{guest.email}</TableCell>
                                <TableCell align="center">{guest.score}</TableCell>
                                <TableCell align="center">{convertLinuxDateToDate(guest.joined_at!)}</TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
        ;
};

export default RoleplayGuestsList;
