import React, {useEffect, useState} from "react";
// @ts-ignore
import asianManVideo from "../../../assets/asianman.mp4";
// @ts-ignore
import blackWomanVideo from "../../../assets/blackwoman.mp4";

import {
    Button,
    Card, FormControl,
    IconButton, InputLabel, MenuItem, Select, SelectChangeEvent,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {MdContentCopy, MdSettingsVoice} from "react-icons/md";
import {IoIosVolumeHigh} from "react-icons/io";
import {toast} from "react-toastify";
import {TfiExport} from "react-icons/tfi";
import {useTranslation} from "react-i18next";
import {reset} from "../../../utils/redux/store/promptSlice";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";

export const TransformationContent = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [reqData, setReqData] = useState({
        topic: "Quantum Computing: A Concise Overview",
        gender: "male",
        ethnicity: "white",
        expertise: "professor"
    })
    const copyContentRef = React.useRef(null);
    const prompt = useAppSelector(state => state.prompt.entity);
    const loading: boolean = useAppSelector(state => state.prompt.loading);
    const [show, setShow] = useState(false)
    const handleTransformContent = (e: { preventDefault: () => void; }) => {
        if (reqData?.topic === "" || reqData.ethnicity === "" || reqData.expertise === "") {
            toast.warning(t("toast.NoFileUploaded"))
            return
        }
        setShow(true)
        // dispatch(pdfSummarizerAPI(reqData)).finally(() => {
        //     dispatch(checkValidate())
        // })
    }
    const handleCopy = () => {
        if (copyContentRef.current) {
            const content = (copyContentRef.current as any).innerHTML;

            // Create a temporary contenteditable div to hold the HTML
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.whiteSpace = 'pre';
            tempDiv.contentEditable = 'true';
            tempDiv.innerHTML = content;
            document.body.appendChild(tempDiv);

            // Select and copy the content
            const range = document.createRange();
            range.selectNodeContents(tempDiv);
            const sel: any = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            // Execute the copy command
            document.execCommand('copy');

            // Clean up
            document.body.removeChild(tempDiv);

            toast.success(t("toast.ContentCopiedToClipboard"));
        }
    }

    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);

    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>
            <Card variant="elevation" className="row mx-0 col-11 col-lg-7 mx-auto p-4 mt-5">
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('TransformContent.title')}</Typography>
                </div>
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3" className="fw-bold text-center poweredbyahura">{t('PoweredByAhuraAI')}</Typography>
                </div>
                <div className="col-12 text-center">
                    <Typography variant="caption">
                        {t('TransformContent.desc')}
                    </Typography>
                </div>

                <div className="col-12 mt-3">
                    <div className="col-12 position-relative">
                        <Typography variant="subtitle1">{t('TransformContent.topicAndDetailsOfTheCourse')}:</Typography>
                        <TextField
                            className="col-12"
                            variant="outlined"
                            placeholder={t('TransformContent.topicAndDetailsOfTheCourseDesc')}
                            value={reqData.topic}
                            onChange={(event) => setReqData(
                                {...reqData, topic: event.target.value}
                            )}
                        />
                        <div className='position-absolute right-4 bottom-4'>
                            {/* for voice and attach */}
                            <IconButton className="col-12">
                                <MdSettingsVoice/>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 col-12 mt-3">
                    <div className="col align-content-center">
                        <Typography variant="caption">Output Options:</Typography>
                    </div>
                    <div className="col">
                        <FormControl fullWidth>
                            <InputLabel id="Gender-select-label">{t('TransformContent.Gender')}</InputLabel>
                            <Select
                                variant="outlined"
                                id="Gender-select"
                                value={reqData.gender!}
                                label={t('TransformContent.Gender')}
                                onChange={(event: SelectChangeEvent) => setReqData({
                                    ...reqData,
                                    gender: event.target.value,
                                    ethnicity: event.target.value === 'male' ? "asian" : "black"
                                })}
                            >
                                <MenuItem value="male">{t('TransformContent.GenderMale')}</MenuItem>
                                <MenuItem value="female">{t('TransformContent.GenderFemale')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col">
                        <FormControl fullWidth>
                            <InputLabel id="ethnicity-select-label">{t('TransformContent.ethnicity')}</InputLabel>
                            <Select
                                variant="outlined"
                                id="ethnicity-select"
                                value={reqData.ethnicity}
                                label={t('TransformContent.ethnicity')}
                                onChange={(event: SelectChangeEvent) => setReqData({
                                    ...reqData,
                                    ethnicity: event.target.value
                                })}
                            >
                                <MenuItem value="asian">{t('TransformContent.asian')}</MenuItem>
                                <MenuItem value="black">{t('TransformContent.black')}</MenuItem>
                                <MenuItem value="hispanic">{t('TransformContent.hispanic')}</MenuItem>
                                <MenuItem value="white">{t('TransformContent.white')}</MenuItem>
                                <MenuItem value="native-american">{t('TransformContent.nativeAmerican')}</MenuItem>
                                <MenuItem value="pacific-islander">{t('TransformContent.pacificIslander')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col">
                        <FormControl fullWidth>
                            <InputLabel id="expertise-select-label">{t('TransformContent.ethnicity')}</InputLabel>
                            <Select
                                variant="outlined"
                                id="expertise-select"
                                value={reqData.expertise}
                                label={t('TransformContent.ethnicity')}
                                onChange={(event: SelectChangeEvent) => setReqData({
                                    ...reqData,
                                    expertise: event.target.value
                                })}
                            >
                                <MenuItem value="noCeleb">{t('TransformContent.NoCeleb')}</MenuItem>
                                <MenuItem value="fashionModel">{t('TransformContent.FashionModel')}</MenuItem>
                                <MenuItem value="professor">{t('TransformContent.Professor')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="col-12 mt-3 px-0 px-lg-4">
                    <Button className="col-12" variant="contained" color="primary"
                            onClick={handleTransformContent}
                    >
                        {t('LessonPlan.Generate')}
                    </Button>
                </div>

            </Card>
            {show &&
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5">
                    <div className="col-12">
                        <Typography variant="subtitle1">Generated Course :</Typography>
                        <TextField
                            className="col-12"
                            multiline
                            rows={10}
                            type="text"
                            disabled
                            value="Quantum Computing: A Concise Overview
                                Quantum computing is a groundbreaking area of computing based on the principles of quantum mechanics, the fundamental theory in physics that describes nature at the smallest scales, such as atoms and subatomic particles.

                                At the heart of quantum computing are qubits. Unlike classical bits, which can be either 0 or 1, qubits can be in a state of superposition, where they represent both 0 and 1 simultaneously. This ability to be in multiple states at once is a core concept of quantum mechanics. Additionally, qubits can exhibit entanglement, a phenomenon where the state of one qubit is dependent on the state of another, regardless of the distance separating them.

                                Quantum operations are carried out using quantum gates, which are the quantum equivalents of classical logic gates. These gates manipulate the states of qubits and are arranged in sequences known as quantum circuits to perform computations. However, one of the major challenges in quantum computing is quantum decoherence, the loss of quantum coherence, which causes the system to lose its quantum behavior and act more classically.

                                In comparing quantum computing to classical computing, classical systems use bits and deterministic logic gates, while quantum systems use qubits, superposition, and probabilistic quantum gates. This difference allows quantum computers to solve certain types of problems more efficiently than classical computers.
                                 "
                        />
                    </div>
                    <div className="col-12">
                        <video className="col-12" src={reqData.gender === "male" ? asianManVideo : blackWomanVideo}
                               width="800" height="600" controls/>
                    </div>
                    {/*<div className="d-flex gap-3 col-12 text-start">*/}
                    {/*    <div>*/}
                    {/*        <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<MdContentCopy/>}*/}
                    {/*                onClick={handleCopy}*/}
                    {/*        >*/}
                    {/*            {t('Copy')}*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<TfiExport/>}>*/}
                    {/*            {t('Export')}*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<IoIosVolumeHigh/>}>*/}
                    {/*            {t('ReadAloud')}*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                </Card>
            }
            {prompt.result && (
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5">
                    <div ref={copyContentRef}
                         dangerouslySetInnerHTML={{__html: prompt.result}}
                    />
                    <div className="d-flex gap-3 col-12 text-start">
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<MdContentCopy/>}
                                    onClick={handleCopy}
                            >
                                {t('Copy')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<TfiExport/>}>
                                {t('Export')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<IoIosVolumeHigh/>}>
                                {t('ReadAloud')}
                            </Button>
                        </div>

                    </div>
                </Card>
            )}
            {loading &&
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5 h-25">
                    <Skeleton className="row mx-0 col-7 mx-auto p-4 mt-5">
                    </Skeleton>
                </Card>
            }
        </div>
    )
}
