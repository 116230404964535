export interface IDiscount {
    id?: string;
    user_id?: number;
    code?:string|null;
    discount_type?: string|null;
    discount_value?:number|null;
    max_reduction_amount?:number|null;
    max_usage_count?:number|null;
    is_active?:boolean|null;
    expiration_date?: number | null;
}

export const defaultValue: Readonly<IDiscount> = {};
