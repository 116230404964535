// src/store/authSlice.ts
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import {getToken, removeToken, setToken} from "../../localstorage/localStorage";
import axios, {AxiosResponse} from "axios";
import {cleanEntity} from "../reducers/entity.utils";
import {AppThunk} from "../store";


export const clearAuthentication = (messageKey: any) => (dispatch: any) => {
    clearAuthToken();
    dispatch(authError(messageKey));
    dispatch(clearAuth());
};
export const clearAuthToken = () => {
    if (getToken()) {
        removeToken();
    }

};
export const logout: () => AppThunk = () => dispatch => {
    clearAuthToken();
    dispatch(logoutSession());
};
const initialState: EntityState<IAccount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
    registerSuccess: false
};

const apiUrl = 'api/auth';

export const getEntity = createAsyncThunk(
    'guarantor/fetch_entity',
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<IAccount>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)

export const loginUser: (email: string, password: string) => AppThunk =
    (email, password) =>
        async dispatch => {
            const result = await dispatch(authenticate({email, password}));
            const response = result.payload as AxiosResponse;
            const bearerToken = response?.data?.access_token;
            setToken(bearerToken)
            if (result.payload) {
                dispatch(checkValidate());
            }
        };
export const loginUserForAhura: (email: string, guid: string) => AppThunk =
    (email, guid) =>
        async dispatch => {
            const result: any = await dispatch(authenticateForAhura({email, guid}));
            const response = result.payload as AxiosResponse;
            if (result.error == null) {
                const bearerToken = response?.data?.access_token;
                setToken(bearerToken)
                if (result.payload) {
                    dispatch(checkValidate());
                }
            }
            window.location.href = "/"
        };
export const authenticateForAhura = createAsyncThunk(
    'auth/loginFromAhura',
    async (entity: { email: string, guid: string }) => {
        const requestUrl = `${apiUrl}/loginFromAhura`;
        return axios.post<IAccount>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError,},
);
export const authenticate = createAsyncThunk(
    'auth/login',
    async (entity: { email: string, password: string }) => {
        const requestUrl = `${apiUrl}/login`;
        return axios.post<IAccount>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError,},
);
export const verifyEmailAPI = createAsyncThunk(
    'auth/verifyEmail',
    async (entity: { guid: string }) => {
        const requestUrl = `${apiUrl}/verifyEmail`;
        return axios.post<IAccount>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError,},
);
export const forgetPasswordAPI = createAsyncThunk(
    'auth/forgetPassword',
    async (entity: { email: string }) => {
        const requestUrl = `${apiUrl}/forgetPassword`;
        return axios.post<IAccount>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError,},
);
export const resetPasswordAPI = createAsyncThunk(
    'auth/resetPassword',
    async (entity: { newPassword: string, guid: string }) => {
        const requestUrl = `${apiUrl}/resetPassword`;
        return axios.post<IAccount>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError,},
);
export const registerUser = createAsyncThunk(
    'auth/register',
    async (entity: { email: string, password: string }) => {
        const requestUrl = `${apiUrl}/register`;
        return axios.post<IAccount>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError,},
);
export const checkValidate = createAsyncThunk(
    'auth/validate',
    async () => {
        const requestUrl = `${apiUrl}/validate`;
        return axios.get<IAccount>(requestUrl);
    },
    {serializeError: serializeAxiosError,},
);

export const authSlice = createEntitySlice({
    name: 'auth',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(checkValidate.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkValidate.fulfilled, (state, action) => {
                state.entity = action.payload.data;
                state.loading = false;
                (state as any).isAuthenticated = true;
            })
            .addCase(checkValidate.rejected, (state) => {
                (state as any).isAuthenticated = false;
                state.loading = false;
            })
            .addMatcher(isFulfilled(getEntity, authenticate, verifyEmailAPI, authenticateForAhura, registerUser), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                    registerSuccess: true,
                };
            })
            .addMatcher(isPending(getEntity, authenticate, verifyEmailAPI, authenticateForAhura, registerUser), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = authSlice.actions;

export default authSlice.reducer;
