import React from 'react';
import {Box, Button, Container, Typography} from "@mui/material";
import {FaTimesCircle} from "react-icons/fa";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../utils/redux/store";
import {getTransactionById} from "../../utils/redux/store/userSlice";
import {IGetTransactionByIdResponse} from "../../utils/redux/model/getTransactionById.model";

const PaymentFailed = () => {
    const dispatch = useAppDispatch();
    const {transactionId} = useParams<string>()
    const handleRetryPayment = () => {
        dispatch(getTransactionById({transactionId: transactionId})).then((res) => {
            if (!(res as any).error) {
                if ((res.payload as any).status == 200) {
                    const response: IGetTransactionByIdResponse = (res.payload as any).data
                    if (typeof response.stripe_url === "string") {
                        window.location.href = response.stripe_url
                    }
                }
            }
        })
    }
    return (
        <Container maxWidth="sm" style={{textAlign: 'center', marginTop: '50px'}}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <FaTimesCircle size={100} color="red"/>
                <Typography variant="h4" component="h1" gutterBottom>
                    Payment Failed
                </Typography>
                <Typography variant="h6" component="p" color="textSecondary">
                    Unfortunately, your payment could not be processed. Please try again.
                </Typography>
                <Button variant="contained" color="primary"
                        style={{marginTop: '20px', width: '200px', backgroundColor: "red"}}
                        onClick={handleRetryPayment}>
                    Retry Payment
                </Button>
                <Link to="/">
                    <Button className="col-12" variant="contained" color="primary"
                            style={{marginTop: '20px', width: '200px'}}>
                        Home Page
                    </Button>
                </Link>

            </Box>
        </Container>
    )
}
export default PaymentFailed;
