import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import {EntityState} from "../../../components/shared/interface/interfaces";
import {createAsyncThunk, isFulfilled, isPending} from "@reduxjs/toolkit";
import {defaultValue, IRolePlay} from "../model/roleplay.model";
import axios from "axios";
import {cleanEntity} from "../reducers/entity.utils";

const initialState: EntityState<IRolePlay> = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/roleplay';

export const getListOfRoomsForCurrenUser = createAsyncThunk(
    'roleplay/fetch_getListOfRoomsForCurrenUser',
    async () => {
        const requestUrl = `${apiUrl}/getListOfRoomsForCurrenUser`;
        return axios.get<IRolePlay>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const CreateRoomAPI = createAsyncThunk(
    'roleplay/CreateRoomAPI',
    async (entity: IRolePlay) => {
        const requestUrl = `${apiUrl}/createRoom`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const getRoomDetailByRoomIdAPI = createAsyncThunk(
    'roleplay/getRoomDetailByRoomIdAPI',
    async (entity: { guid: string }) => {
        const requestUrl = `${apiUrl}/getRoomDetailByRoomId`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const CloseRoomAPI = createAsyncThunk(
    'roleplay/CloseRoomAPI',
    async (entity: { room_id: number }) => {
        const requestUrl = `${apiUrl}/closeRoom`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const GetListOfGuestsInRoomAPI = createAsyncThunk(
    'roleplay/GetListOfGuestsInRoomAPI',
    async (entity: { guid: string }) => {
        const requestUrl = `${apiUrl}/getListOfGuestsInRoom`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const JoinTheRoomByRoomIdAPI = createAsyncThunk(
    'roleplay/JoinTheRoomByRoomIdAPI',
    async (entity: { room_guid: string, name: string, email: string }) => {
        const requestUrl = `${apiUrl}/joinTheRoomByRoomId`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const GetListOfMessagesInRoomByGuestIdAPI = createAsyncThunk(
    'roleplay/getListOfMessagesInRoomByGuestIdAPI',
    async (entity: { room_guid: string, guest_id: string }) => {
        const requestUrl = `${apiUrl}/getListOfMessagesInRoomByGuestId`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const RoleplayChatBoxAPI = createAsyncThunk(
    'roleplay/RoleplayChatBoxAPI',
    async (entity: { room_guid: string, guest_id: string, employee_reply: string }) => {
        const requestUrl = `${apiUrl}/roleplayChatBox`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const EndChatAndGetScoreAPI = createAsyncThunk(
    'roleplay/EndChatAndGetScoreAPI',
    async (entity: { room_guid: string, guest_id: string }) => {
        const requestUrl = `${apiUrl}/endChatAndGetScore`;
        return axios.post<IRolePlay>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)

export const roleplaySlice = createEntitySlice({
    name: 'roleplay',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(CreateRoomAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(CreateRoomAPI.fulfilled, (state, action) => {
                // state.entity = action.payload.data;
                (state as any).loadingCreateRoomAPI = false;
            })
            .addCase(CreateRoomAPI.rejected, (state) => {
                (state as any).loadingCreateRoomAPI = false;
            })
            .addCase(GetListOfMessagesInRoomByGuestIdAPI.rejected, (state) => {
                (state as any).GetListOfMessagesInRoomByGuestIdAPIError = true;
            })
            .addCase(JoinTheRoomByRoomIdAPI.pending, (state) => {
                (state as any).GetRoomDetailByRoomIdAPI = true;
            })
            .addCase(JoinTheRoomByRoomIdAPI.fulfilled, (state, action) => {
                state.entity = action.payload.data;
                (state as any).GetRoomDetailByRoomIdAPI = false;
            })
            .addCase(JoinTheRoomByRoomIdAPI.rejected, (state) => {
                (state as any).GetRoomDetailByRoomIdAPI = false;
            })
            .addCase(getRoomDetailByRoomIdAPI.rejected, (state) => {
                (state as any).getRoomDetailByRoomIdAPIError = true;
            })
            .addMatcher(isFulfilled(RoleplayChatBoxAPI, EndChatAndGetScoreAPI, getListOfRoomsForCurrenUser, getRoomDetailByRoomIdAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entity: data,
                };
            })
            .addMatcher(isFulfilled(GetListOfGuestsInRoomAPI,getListOfRoomsForCurrenUser, GetListOfMessagesInRoomByGuestIdAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(GetListOfGuestsInRoomAPI,RoleplayChatBoxAPI, EndChatAndGetScoreAPI, getListOfRoomsForCurrenUser, getRoomDetailByRoomIdAPI, GetListOfMessagesInRoomByGuestIdAPI), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = roleplaySlice.actions;

export default roleplaySlice.reducer;
