import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {
    Badge, Button, CircularProgress,
    Divider,
    Drawer,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem, Typography
} from "@mui/material";
import Logout from "../auth/logout";
import IconButton from "@mui/material/IconButton";
import {MdAccountCircle, MdFavorite} from "react-icons/md";
import {Link} from "react-router-dom";
import {AUTHORITIES, hasAnyAuthority} from "../../AppRoutes";
import {RxHamburgerMenu} from "react-icons/rx";
import Box from "@mui/material/Box";
import {IoIosArrowDown, IoMdHome} from "react-icons/io";
import {LanguageSwitcher} from "./languageSwitcher";
import {setPage, setSelectedList} from "../../utils/redux/store/promptHistorySlice";
import {IPromptHistory} from "../../utils/redux/model/promptHistory.model";
import {useTranslation} from "react-i18next";
import {FaCircle} from "react-icons/fa";

export const SidebarHeader = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation()
    const promptHistories: IPromptHistory[] = useAppSelector(state => state.promptHistory.entities);
    const hasMore: boolean = useAppSelector(state => state.promptHistory.entities[0]?.hasMore);
    const page: number = useAppSelector(state => state.promptHistory.page);
    const promptHistoriesLoading: boolean = useAppSelector(state => state.promptHistory.loading);
    const selectedList: IPromptHistory = useAppSelector(state => state.promptHistory.selectedList);
    const email: string = useAppSelector(state => state.auth.entity.email);
    const subscription: string = useAppSelector(state => state.auth.entity.subscription);
    const userBalance: string = useAppSelector(state => state.auth.entity.userBalance);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isNotifOpen, setIsNotifOpen] = React.useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorNotifEl, setAnchorNotifEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openNotif = Boolean(anchorNotifEl);
    const isInvitee = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.INVITEE]));
    const isOrganization = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.ORGANIZATION]));
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleNotifClick = (event: any) => {
        setAnchorNotifEl(event.currentTarget);
    };
    const toggleDrawer = (newOpen: boolean) => () => {
        setIsOpen(newOpen);
    };
    const toggleNotifDrawer = (newOpen: boolean) => () => {
        setIsNotifOpen(newOpen);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorNotifEl(null);
    };
    const notifications = [
        {id: 1, title: "New Message example", description: "You have a new message regarding the class schedule.", isRead: false},
        {id: 2, title: "New Assignment Available", description: "A new assignment is available in Math 101.", isRead: true},
    ];
    const DrawerList = (
        <Box sx={{width: 250}} role="presentation">
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/">
                    <ListItemIcon>
                        <IoMdHome size={23}/>
                    </ListItemIcon>
                    <ListItemText primary="Home"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/favorites">
                    <ListItemIcon>
                        <MdFavorite size={23}/>
                    </ListItemIcon>
                    <ListItemText primary="User Favorites"/>
                </ListItemButton>
            </ListItem>
            {isOrganization && <ListItem disablePadding>
                <ListItemButton component={Link} to="/inviteTeachers">
                    <ListItemIcon>
                        <IoMdHome size={23}/>
                    </ListItemIcon>
                    <ListItemText primary="invite Teachers"/>
                </ListItemButton>
            </ListItem>}
            <ListItem disablePadding>
                <ListItemButton component={Link} to="/">
                    <LanguageSwitcher/>
                </ListItemButton>
            </ListItem>
            {promptHistoriesLoading ?
                <CircularProgress/>
                : promptHistories.map((promptHistory) => {
                    return (
                        <ListItem disablePadding className={selectedList.createdDate === promptHistory.createdDate ? "bg-green" : ""}>
                            <ListItemButton
                                onClick={() => {
                                    dispatch(setSelectedList(promptHistory))
                                }}
                            >
                                <ListItemText className="prompthistorysidetitle"
                                              primary={promptHistory.prompt?.includes("lesson plan") ?
                                                  promptHistory.prompt?.split("with the topic, standard, or objective:")[1]?.split("and additional criteria:")[0]
                                                  : promptHistory.prompt?.includes("Counseling") ?
                                                      promptHistory.prompt?.split("with Topic, Standard, or Objective:")[1]?.split("and no comment")[0]
                                                      :
                                                      null
                                              }/>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            <Divider/>
            {promptHistories.length != 0 &&
                <ListItem disablePadding>
                    <ListItemButton>
                        <Button disabled={!hasMore} className="col-12" variant="outlined" color="primary"
                                onClick={() => dispatch(setPage(page + 1))}
                        >
                            See More
                        </Button>
                    </ListItemButton>
                </ListItem>

            }

            <Divider/>
            <ListItem disablePadding>
                <ListItemButton className="text-center">
                    <ListItemText primary="logout"/>
                </ListItemButton>
            </ListItem>
        </Box>
    );
    return (
        <div className="d-flex gap-2 mx-0 col-12 p-2 mx-0 bg-light header dir-rtl bg-white">
            <div className="d-flex gap-2 align-content-center dir-ltr">
                {showLogoutModal && <Logout setShowLogoutModal={setShowLogoutModal}/>}
                <Button className="border-0 gap-2" variant="outlined"
                        onClick={handleClick}
                >
                    <div className="col">
                        <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <MdAccountCircle size={50}/>
                        </IconButton>
                    </div>
                    <div className="col-5 text-start align-content-center text-truncate">
                        {email}
                        <br/>
                        {subscription === "" ? "Free User" : subscription + " User"}
                    </div>
                    <div className="col align-content-center">
                        <div>
                            <IoIosArrowDown className="border border-1 rounded-circle" size={15}/>
                        </div>
                    </div>
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {isInvitee ?
                        <MenuItem>{t('Wallet.Wallet')}: ${Number(userBalance).toFixed(2)} </MenuItem>
                        :
                        <React.Fragment>
                            <Link to="/user/chargeWallet">
                                <MenuItem>
                                    {t('Wallet.Wallet')}: ${Number(userBalance).toFixed(4)}
                                    <span className="position-absolute right-15 text-danger">
                                    {t('Wallet.clickToCharge')}
                                </span>
                                </MenuItem>
                            </Link>
                            <Link to="/user/subscriptionPlan">
                                <MenuItem>
                                    subscription Plan
                                </MenuItem>
                            </Link>
                        </React.Fragment>
                    }

                    <Link to={isInvitee ? "/inviteeSetting" : "/setting"}> <MenuItem>{t('Wallet.MyAccount')}</MenuItem></Link>
                    <MenuItem onClick={() => {
                        handleClose()
                        setShowLogoutModal(true)
                    }}
                    ><span className="text-danger">{t('Wallet.Logout')}</span></MenuItem>
                </Menu>
            </div>
            {window.innerWidth <= 1024 ?
                <div className="align-content-center w-100 text-start">
                    <IconButton onClick={toggleDrawer(true)}>
                        <RxHamburgerMenu/>
                    </IconButton>
                    <Drawer open={isOpen} onClose={toggleDrawer(false)}>
                        {DrawerList}
                    </Drawer>
                </div>
                :
                <React.Fragment>
                    <div className="align-content-center text-end dir-ltr w-3">
                        <LanguageSwitcher/>
                    </div>
                    <div className="text-end align-content-center">
                        {/* Notification Icon Button */}
                        <IconButton onClick={handleNotifClick} sx={{color: '#3f51b5'}}>
                            <Badge badgeContent={notifications.length} color="error">
                                <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M10.0277 0C7.73472 0 5.80843 1.72411 5.55522 4.00306L4.5 13.5H1.5C0.671573 13.5 0 14.1716 0 15V16.5C0 17.3284 0.671573 18 1.5 18H22.5C23.3284 18 24 17.3284 24 16.5V15C24 14.1716 23.3284 13.5 22.5 13.5H19.5L18.4448 4.00306C18.1916 1.72411 16.2653 0 13.9723 0H10.0277Z"
                                          fill="#49C12C"/>
                                    <rect opacity="0.3" x="9" y="19.5" width="6" height="6" rx="2.25" fill="#FF0000"/>
                                </svg>
                            </Badge>
                        </IconButton>

                        {/* Notification Menu */}
                        <Menu
                            anchorEl={anchorNotifEl}
                            open={openNotif}
                            onClose={handleClose}
                            PaperProps={{
                                elevation: 1,
                                sx: {
                                    borderRadius: 3,
                                    maxHeight: 300,
                                    width: '350px',
                                    padding: '0px',
                                },
                            }}
                        >
                            <div className="p-0">
                                <div className="px-4">
                                    <Typography variant="h6" color="textPrimary" sx={{fontWeight: 'bold'}}>
                                        Notifications
                                    </Typography>
                                </div>
                                <Divider sx={{my: 1}}/>
                            </div>

                            <div className="mt-3">
                                {notifications.length > 0 ? (
                                    notifications.map((notification) => (
                                        <div key={notification.id}>
                                            <MenuItem onClick={handleClose}>
                                                {/* Read/Unread Dot */}
                                                {!notification.isRead
                                                    &&
                                                    <FaCircle
                                                        style={{
                                                            right: '10',
                                                            top: '5',
                                                            position: 'absolute',
                                                            fontSize: 10,
                                                            color: '#3f51b5', // Grey if read, primary color if unread
                                                        }}
                                                    />
                                                }
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle1" sx={{fontWeight: 'medium', color: '#000'}}>
                                                            {notification.title}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography className="text-truncate" variant="body2" color="textSecondary">
                                                            {notification.description}
                                                        </Typography>
                                                    }
                                                />
                                            </MenuItem>
                                            <Divider/>
                                        </div>
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        <Typography variant="body2" color="textSecondary">
                                            No notifications
                                        </Typography>
                                    </MenuItem>
                                )}
                            </div>

                            {/* Show All Notifications Button */}
                            <MenuItem onClick={handleClose} sx={{justifyContent: 'center'}}>
                                <Button variant="outlined" color="primary" href="/notifications">
                                    Show all notifications
                                </Button>
                            </MenuItem>
                        </Menu>
                    </div>
                    <div className="text-end align-content-center">
                        <Button variant="contained" color="secondary" component={Link} target="_blank" to="https://www.youtube.com/channel/UCeVK0FdV-pkcUo6pDYT57FQ">
                            Tutorial
                        </Button>
                    </div>
                </React.Fragment>
            }


        </div>
    )
}

