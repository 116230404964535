import React, {useState} from 'react';
import {Avatar, Button, Card, Input, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {t} from "i18next";
import {FcSupport} from "react-icons/fc";
import profile from '../../assets/profile.png'
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {setOrganizationIconAPI} from "../../utils/redux/store/userSlice";
import {checkValidate} from "../../utils/redux/store/authSlice";

export const OrganizationSetting = () => {
    const dispatch = useAppDispatch();
    const [avatarMode, setAvatarMode] = useState(false);
    const {email, userBalance, language, organizationIcon} = useAppSelector(state => state.auth.entity);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    dispatch(setOrganizationIconAPI({organizationIcon: reader.result})).finally(() => {
                        dispatch(checkValidate())
                    })
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>
            <Card variant="elevation" className="row mx-0 col-11 col-lg-7 mx-auto p-4 mt-5">
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('user.Setting')}</Typography>
                </div>
                <div className='col-12 text-center align-self-center mt-3 px-0'>
                    <Input
                        hidden
                        type='file'
                        id='profileInputId'
                        onChange={handleFileChange}
                    />
                    <label htmlFor='profileInputId'>
                        {avatarMode ?
                            <div className='position-relative' onMouseLeave={() => setAvatarMode(false)}
                                 onMouseEnter={() => setAvatarMode(true)}>
                                <Avatar className='avatarProfilePicHover' variant='circular' component='span'
                                        sx={{height: 88, width: 88}} src={organizationIcon}>
                                    <FcSupport/>
                                </Avatar>
                                <div
                                    className='avatarProfilePicHoverDiv position-absolute align-self-center text-center top-0'>
                                    <img src={profile} style={{marginTop: '30px'}} alt="profile"/>
                                </div>
                            </div>
                            :
                            <Avatar className='avatarprofilepic' onMouseLeave={() => setAvatarMode(false)}
                                    onMouseEnter={() => setAvatarMode(true)} variant='circular' component='span'
                                    sx={{height: 88, width: 88}} src={organizationIcon}
                            />
                        }
                    </label>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="h5">
                        email: {email}
                    </Typography>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="h5">
                        Balance: {Number(userBalance).toFixed(4)}$
                    </Typography>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="h5">
                        Current language: {language}
                    </Typography>
                </div>
            </Card>
        </div>
    );
};
