// src/store/promptSlice.ts
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import axios from "axios";
import {IPromptHistory} from "../model/promptHistory.model";


const initialState: EntityState<IAccount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
    page: 1,
    selectedList: {} as IPromptHistory,
};

const apiUrl = 'api/prompts';

export const lessonPlanHistoryAPI = createAsyncThunk(
    'promptsHistory/fetch_lessonPlanHistoryAPI',
    async (entity: { page: number }) => {
        const requestUrl = `${apiUrl}/lessonPlanHistory?limit=${entity.page * 10}&offset=0`;
        return axios.get<IAccount>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const counselingPlanHistoryAPI = createAsyncThunk(
    'promptsHistory/fetch_lessonPlanHistoryAPI',
    async (entity: { page: number }) => {
        const requestUrl = `${apiUrl}/counselingPlanHistory?limit=${entity.page * 10}&offset=0`;
        return axios.get<IAccount>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const setPage = createAsyncThunk(
    'promptsHistory/fetch_setPage',
     (page: number) => {
        return page;
    },
    {serializeError: serializeAxiosError}
)
export const setSelectedList = createAsyncThunk(
    'promptsHistory/fetch_setSelectedList',
     (selectedList: IPromptHistory) => {
        return selectedList;
    },
    {serializeError: serializeAxiosError}
)

export const promptHistorySlice = createEntitySlice({
    name: 'prompts',
    initialState,

    extraReducers: (builder) => {
        builder

            .addCase(setPage.fulfilled, (state: any, action: any) => {
                return {
                    ...state,
                    page: action.payload,
                };
            })
            .addCase(setSelectedList.fulfilled, (state: any, action: any) => {
                return {
                    ...state,
                    selectedList: action.payload,
                };
            })
            .addMatcher(isFulfilled(lessonPlanHistoryAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(lessonPlanHistoryAPI), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = promptHistorySlice.actions;

export default promptHistorySlice.reducer;
