import React, {useState} from 'react';
import {Button, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../utils/redux/store";
import {forgetPasswordAPI} from "../../utils/redux/store/authSlice";
import {toast} from "react-toastify";
import loginslide1 from "../../assets/loginslide1.png";
import loginslide2 from "../../assets/loginslide2.jpeg";
import loginslide3 from "../../assets/loginslide3.jpeg";
import loginslide4 from "../../assets/loginslide4.jpeg";
import {useTranslation} from "react-i18next";
import SimpleImageSlider from "react-simple-image-slider";

const ForgetPassword = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const handleForgetPass = () => {
        if (email === "") {
            toast.warning("please fill the form")
            return
        }
        dispatch(forgetPasswordAPI({email: email})).then((res) => {
            if (res.payload) {
                navigate("/")
            }
        })
    }
    return (
        <div className="row mx-0 h-100">
            <div className="col-12 col-lg-6 mt-5 px-5">
                <div className='row justify-content-center mt-5'>
                    <div className='col-12'>
                        <Typography className='text-center' variant='h2' component='h1'>
                            Forget Password
                        </Typography>
                    </div>
                    <div className='col-12 mt-3'>
                        <Typography className='text-center' variant='caption' component='h1'>
                            Forget your password
                        </Typography>
                    </div>
                </div>
                <div className='row justify-content-center mt-5'>
                    <div className='col-10'>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('login.email')}:</Typography>
                            <TextField
                                className="col-12 mt-2"
                                variant="filled"
                                type="email"
                                placeholder={t('login.emailPlaceholder')}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <div className="col-10 mx-auto mt-3 px-0 px-lg-4">
                                <Button className="col-12" variant="contained" color="primary"
                                        onClick={handleForgetPass}
                                >
                                    {t('login.ForgetPass')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 text-center mt-3'>
                    <Typography className='col-12 text-center' variant='h5' component='h6'>
                        Don’t have an account?
                        <a href='/register' style={{color: '#F87F35'}}> Create Account</a>
                    </Typography>
                </div>
            </div>
            <div className="col-12 col-lg-6 px-0 d-none d-lg-block slideimg h-100">
                <SimpleImageSlider
                    width='100%'
                    height='100%'
                    slideDuration={0.8}
                    images={[
                        loginslide1,
                        loginslide2,
                        loginslide3,
                        loginslide4,
                    ]}
                    autoPlay={true}
                    autoPlayDelay={20000}
                    showBullets={false}
                    showNavs={true}
                />
            </div>

        </div>
    )

};

export default ForgetPassword;
