import React from 'react';
import {Box, Button, Container, Typography} from "@mui/material";
import {FaCheckCircle} from "react-icons/fa";
import {Link, useParams} from "react-router-dom";

const PaymentSuccess = () => {
    const {transactionId} = useParams<string>()
    return (
        <Container maxWidth="sm" style={{textAlign: 'center', marginTop: '50px'}}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <FaCheckCircle size={100} color="green"/>
                <Typography variant="h4" component="h1" gutterBottom>
                    Payment Successful!
                </Typography>
                <Typography variant="h6" component="p" color="textSecondary">
                    Thank you for your purchase. Your payment has been successfully processed.
                </Typography>
                <Link to="/">
                    <Button variant="contained" color="primary" style={{marginTop: '20px'}}>
                        Go to Dashboard
                    </Button>
                </Link>
            </Box>
        </Container>
    )
}
export default PaymentSuccess;
