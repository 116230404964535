import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import MUITheme from "./utils/themes";
import {ToastContainer} from 'react-toastify';
import {CssBaseline, ThemeProvider} from '@mui/material';
import AppRoutes from "./AppRoutes";
import "./utils/globalcss.css";
import {Provider} from 'react-redux';
import './i18n';
import getStore from "./utils/redux/store";
import setupAxiosInterceptors from "./utils/redux/axiosInstance";
import {bindActionCreators} from "@reduxjs/toolkit";
import {clearAuthentication} from "./utils/redux/store/authSlice";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const store = getStore();
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

root.render(
    <ThemeProvider theme={MUITheme}>
        <CssBaseline/> {/* Ensures consistent styling across browsers */}
        <Provider store={store}>
            <AppRoutes/>
            <ToastContainer/>
        </Provider>
    </ThemeProvider>,
);

// reportWebVitals();
