import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../utils/redux/store";
import {loginUserForAhura} from "../../utils/redux/store/authSlice";
import {Typography} from "@mui/material";
import {PageNotFound} from "../shared/PageNotFound";

const AhuraUserSSO = () => {
    const dispatch = useAppDispatch()
    const {email, guid} = useParams();
    useEffect(() => {
        dispatch(loginUserForAhura(email!, guid!))
    }, []);
    if (email === null && guid === null) {
        return <PageNotFound/>
    } else {
        return (
            <div className="col-12 text-center align-content-center align-self-center mt-5 ">
                <Typography className="mt-5" variant="h2">
                    Loading...
                </Typography>
                <Typography className="mt-3" variant="h2">
                    Please Wait
                </Typography>
            </div>
        );
    }

};

export default AhuraUserSSO;
