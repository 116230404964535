import React, {useState} from 'react'
import {
    Button,
    Card,
    CardActions, IconButton,
    Paper, styled,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {IRolePlay} from "../../../utils/redux/model/roleplay.model";
import {IoCloseCircleOutline} from "react-icons/io5";
import {FaRegCopy} from "react-icons/fa";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../../utils/redux/store";
import {CloseRoomAPI, getListOfRoomsForCurrenUser} from "../../../utils/redux/store/rolePlaySlice";
import {convertLinuxDateToDate} from "../../../utils/convertLinuxDateToDate";
import {IGuest} from "../../../utils/redux/model/guest.model";
import qrcodeIcon from "../../../assets/qrcode.png";
import QRCode from 'qrcode.react';
import {IoMdChatboxes} from "react-icons/io";
import {Link} from "react-router-dom";

export const RoomCard = (props: IRolePlay) => {
    const dispatch = useAppDispatch()
    const handleCopyRoomLink = () => {
        const roomLink = `${window.location.origin}/tools/roleplay-generator/room/${props.guid}`;
        navigator.clipboard.writeText(roomLink).then(() => {
            toast.success('Room link copied to clipboard!');
        }).catch(err => {
            toast.error('Failed to copy room link');
        });
    }
    const handleCloseRoom = () => {
        const room_id = props.id!
        dispatch(CloseRoomAPI({room_id: room_id})).finally(() => {
            dispatch(getListOfRoomsForCurrenUser())
        })
    }

    return (
        <div className="row mx-0 col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-4 ">
            <Card className="row mx-0 col-12 p-3 " variant="outlined">
                <div className="col-2 align-content-center ">
                    <QRCode onClick={handleCopyRoomLink}
                            value={window.location.origin + "/tools/roleplay-generator/room/" + props.guid}/>
                </div>
                <div className="col-10 align-content-center text-start">
                    <Typography className="col-12 mt-3" variant="h3">
                        {props.room_name}
                    </Typography>
                    <Typography className="col-12 mt-3" variant="caption">
                        scenario {props.scenario}
                    </Typography>
                </div>
                <div className="col-12 text-start ">
                    {props.guests?.length !== 0 &&
                        <TableContainer className="mt-4" component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="fw-bold fs-6">Student Name</TableCell>
                                        <TableCell className="fw-bold fs-6" align="center">email</TableCell>
                                        <TableCell className="fw-bold fs-6" align="center">score</TableCell>
                                        <TableCell className="fw-bold fs-6" align="center">joined at</TableCell>
                                        <TableCell className="fw-bold fs-6" align="center">go to chat</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.guests?.map((guest) => (
                                        <StyledTableRow
                                            key={guest.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>{guest.name}</TableCell>
                                            <TableCell
                                                align="center">{guest.email !== "null" ? guest.email : "not set"}</TableCell>
                                            <TableCell
                                                align="center">{guest.score === 0 ? "no score yet" : guest.score}</TableCell>
                                            <TableCell
                                                align="center">{convertLinuxDateToDate(guest.joined_at!)}</TableCell>
                                            <TableCell align="center">
                                                <a target="_blank"
                                                    href={window.location.origin + "/tools/roleplay-generator/room/" + props.guid + "/" + guest.id}>
                                                    <IconButton>
                                                        <IoMdChatboxes/>
                                                    </IconButton>
                                                </a>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                </div>
                <CardActions>
                    <Button className="" variant="outlined" color="secondary" startIcon={<FaRegCopy/>}
                            onClick={handleCopyRoomLink}>
                        copy link
                    </Button>
                    <Button variant="outlined" color="secondary" startIcon={<IoCloseCircleOutline/>}
                            onClick={() => !props.is_closed ? handleCloseRoom() : () => {
                            }}>
                        {props.is_closed ? "Room Is Closed" : "close the Room"}
                    </Button>
                </CardActions>
            </Card>
        </div>
    )
}
export const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
