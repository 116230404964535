import React, {useEffect} from 'react';
import {Button, Card, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {t} from "i18next";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {getListOfRoomsForCurrenUser} from "../../../utils/redux/store/rolePlaySlice";
import {IRolePlay} from "../../../utils/redux/model/roleplay.model";
import {RoomCard} from "./roomCard";
import CreateRoomModal from "./modal/createRoomModal";

const RolePlay = () => {
    const dispatch = useAppDispatch()
    const entities: IRolePlay[] = useAppSelector(state => state.roleplay.entities);

    useEffect(() => {
        // Dispatch the action every 10 seconds
        const intervalId = setInterval(() => {
            dispatch(getListOfRoomsForCurrenUser());
        }, 10000); // 10000ms = 10 seconds

        // Initial call to load the rooms immediately
        dispatch(getListOfRoomsForCurrenUser());

        // Clear interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch]);

    return (
        <div className="row mx-0">

            <Card variant="elevation" className="row mx-0 col-12 mx-auto p-4 ">
                <div className="col-4 text-start mt-3">
                    {/* @ts-ignore*/}
                    <Button
                        LinkComponent={Link}
                        to="/tools"
                        variant="contained"
                        color="secondary"
                        className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                        startIcon={<IoArrowBack/>}
                    >
                        Back
                    </Button>
                </div>
                <div className="col-4 text-center mt-3 align-self-center">
                    <Typography variant="h3">{t('RolePlay.title')}</Typography>
                </div>
                <div className="col-4 text-end mt-3">

                    <CreateRoomModal/>
                </div>

                <div className="col-12 text-center mt-4">
                    <Typography variant="caption">{t('RolePlay.Desc')}</Typography>
                </div>

                <div className="row mx-0 col-12 mt-3">
                    {entities && entities.length > 0 ?
                        entities.map((room) => {
                            return (
                                <RoomCard {...room} />
                            )
                        })
                        :
                        <Typography variant="h5" className="mt-3">{t("RolePlay.noRoomFound")}</Typography>
                    }
                </div>
            </Card>

        </div>
    );
};

export default RolePlay;
