import React, {useEffect, useState} from "react";
import {
    Button,
    Card, CircularProgress,
    FormControl, IconButton,
    InputLabel, List, ListItem, ListItemButton,
    MenuItem,
    Select,
    SelectChangeEvent, Skeleton,
    TextField,
    Typography
} from "@mui/material";
import {FaArrowLeft, FaStar} from "react-icons/fa";
import {MdContentCopy} from "react-icons/md";
import {IoIosAttach, IoIosVolumeHigh} from "react-icons/io";
import {toast} from "react-toastify";
import {TfiExport} from "react-icons/tfi";
import {useTranslation} from "react-i18next";
import {lessonPlanAPI, reset} from "../../../utils/redux/store/promptSlice";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {ILessonPlan, ILessonPlanResponse} from "../../../utils/redux/model/lessonplan.model";
import {checkValidate} from "../../../utils/redux/store/authSlice";
import {Document, Packer, Paragraph, TextRun} from "docx";
import {saveAs} from "file-saver";
import {stripHtmlTags} from "../../../utils/stripHtmlTags";
import html2pdf from 'html2pdf.js';
import {lessonPlanHistoryAPI, setPage, setSelectedList} from "../../../utils/redux/store/promptHistorySlice";
import {IPromptHistory} from "../../../utils/redux/model/promptHistory.model";
import {unixToDate} from "../../../utils/utils";
import {Link} from "react-router-dom";
import {FiRepeat} from "react-icons/fi";
import VoiceToSpeechModal from "../voiceToSpeechModal";

export const LessonPlanGenerator = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [reqData, setReqData] = useState<ILessonPlan>({
        gradeLevel: '',
        topic: '',
        additionalCriteria: '',
        standardsSetToAlignTo: 'CCSS'
    })
    const copyContentRef = React.useRef(null);
    const prompt: ILessonPlanResponse = useAppSelector(state => state.prompt.entity);
    const promptHistories: IPromptHistory[] = useAppSelector(state => state.promptHistory.entities);
    const page: number = useAppSelector(state => state.promptHistory.page);
    const selectedList: IPromptHistory = useAppSelector(state => state.promptHistory.selectedList);
    const hasMore: boolean = useAppSelector(state => state.promptHistory.entities[0]?.hasMore);
    const promptHistoriesLoading: boolean = useAppSelector(state => state.promptHistory.loading);
    const loading: boolean = useAppSelector(state => state.prompt.loading);
    const handleGenerateLessonPlans = (e: { preventDefault: () => void; }) => {
        if (reqData.topic === '' || reqData.additionalCriteria === '' || reqData.gradeLevel === '' || reqData.standardsSetToAlignTo === '') {
            toast.warning(t("toast.FillTheFormFirst"))
            return
        }
        dispatch(lessonPlanAPI(reqData)).finally(() => {
            dispatch(checkValidate())
            dispatch(lessonPlanHistoryAPI({page: 1}))

        })
    }

    const handleCopy = () => {
        if (copyContentRef.current) {
            const content = (copyContentRef.current as any).innerHTML;

            // Create a temporary contenteditable div to hold the HTML
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.whiteSpace = 'pre';
            tempDiv.contentEditable = 'true';
            tempDiv.innerHTML = content;
            document.body.appendChild(tempDiv);

            // Select and copy the content
            const range = document.createRange();
            range.selectNodeContents(tempDiv);
            const sel: any = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            // Execute the copy command
            document.execCommand('copy');

            // Clean up
            document.body.removeChild(tempDiv);

            toast.success('Content copied to clipboard.');
        }
    }
    const handleExport = () => {
        if (!copyContentRef.current) {
            toast.error('No content to export');
            return;
        }
        const content = copyContentRef.current;
        const opt = {
            margin: 1,
            filename: 'Lesson-plans.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'A4', orientation: 'portrait'}
        };

        html2pdf().set(opt).from(content).save();


        const docs = new Document({
            sections: [{
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: stripHtmlTags((content as any).innerHTML),
                            break: 1
                        })
                    ]
                })]
            }]
        });
        Packer.toBlob(docs).then(blob => {
            saveAs(blob, 'Lesson-plans.docx');
        });
    }
    const handleReadAloud = () => {
        toast.info("coming soon")
    }
    useEffect(() => {
        dispatch(lessonPlanHistoryAPI({page: page}))
    }, [page]);
    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);

    return (
        <div className="row mx-0">
            <Card variant="elevation" className="row mx-0 col-12 p-4 pt-0 pb-0 mt-2 bg-white">
                {window.innerWidth > 1024 &&
                    <div className="col-3 border-right ">
                        <Button className="col-12 mt-3" variant="contained" color="primary"
                                onClick={() => {
                                    dispatch(reset())
                                    dispatch(lessonPlanHistoryAPI({page: 1}))
                                    dispatch(setPage(1))
                                    dispatch(setSelectedList({} as IPromptHistory))
                                    setReqData({
                                        gradeLevel: '',
                                        topic: '',
                                        additionalCriteria: '',
                                        standardsSetToAlignTo: 'CCSS'
                                    })
                                }}
                        >
                            {t('LessonPlan.new')}
                        </Button>
                        <div className="col-12 text-center mt-4 h-80 overflow-y-scroll">
                            {promptHistoriesLoading ?
                                <CircularProgress/>
                                :
                                <List>
                                    {
                                        promptHistories.map((promptHistory) => {
                                            return (
                                                <ListItem
                                                    className={selectedList.createdDate === promptHistory.createdDate ? "bg-green rounded-3 mt-2" : "rounded-3 mt-2"}
                                                    disablePadding
                                                    onClick={() => dispatch(setSelectedList(promptHistory))}
                                                >
                                                    <ListItemButton className="row mx-0 p-2">
                                                        <div
                                                            className="col-12 fw-bold prompthistorytitle">
                                                            {
                                                                promptHistory.prompt?.split("with the topic, standard, or objective:")[1]?.split("and additional criteria:")[0]
                                                            }
                                                        </div>
                                                        <Typography variant="caption"
                                                                    className="col-12">{unixToDate(promptHistory.createdDate!)}</Typography>
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            }

                        </div>
                        <div className="col-12 text-center">
                            <Button disabled={!hasMore} className="col-5 mt-3" variant="outlined" color="primary"
                                    onClick={() => dispatch(setPage(page + 1))}
                            >See More</Button>
                        </div>
                    </div>
                }

                {selectedList.prompt ?
                    <div className={window.innerWidth < 1024 ? "row mx-0 col-12 align-content-baseline" : "row mx-0 col-9 align-content-baseline"}>
                        <div className="d-flex gap-2 col-9 px-0 mt-3">
                            <Button
                                variant="text"
                                color='primary'
                                className="gap-0 px-3"
                                startIcon={<FaArrowLeft/>}
                                component={Link}
                                to="/"
                            >
                                {t('back')}
                            </Button>
                            {selectedList.prompt &&
                                <Button variant="text" color='primary' className="gap-0 px-3"
                                        startIcon={<FiRepeat/>}
                                        onClick={() => {
                                            dispatch(reset())
                                            dispatch(lessonPlanHistoryAPI({page: page}))
                                        }}
                                >
                                    {t('Reset')}
                                </Button>
                            }
                        </div>
                        <div className="row mx-0 col-12 align-content-start mt-3 p-3"
                             dangerouslySetInnerHTML={{
                                 __html:
                                     "<h1>" + selectedList.prompt?.split("with the topic, standard, or objective:")[1]?.split("and additional criteria:")[0] +
                                     "</h1><br/><br/> " + selectedList.response!
                             }}/>
                    </div>


                    : !(reqData && prompt.result) &&
                    <div className={window.innerWidth > 1024 ? "row mx-0 col-9 align-content-start" : "row mx-0 col-12 align-content-start"}>
                        <div className="d-flex gap-2 col-12 px-0 mt-3">
                            <Button
                                variant="text"
                                color='primary'
                                className="gap-0 px-3"
                                startIcon={<FaArrowLeft/>}
                                component={Link}
                                to="/"
                            >
                                {t('back')}
                            </Button>
                            {selectedList.prompt &&
                                <Button variant="text" color='primary' className="gap-0 px-3"
                                        startIcon={<FiRepeat/>}
                                        onClick={() => setReqData({gradeLevel: '', topic: '', standardsSetToAlignTo: 'CCSS', additionalCriteria: ''})}
                                >
                                    {t('Reset')}
                                </Button>
                            }

                            <Button variant="text" color='primary' className="gap-0 px-3"
                                    startIcon={<FaStar/>}
                                    onClick={() => setReqData({
                                        gradeLevel: 'Elementary',
                                        topic: 'How do food webs describe the ways in which animals in an ecosystem are related? ',
                                        additionalCriteria: 'This lesson will be an introductory lesson where students are being introduced to the  roles that particular organisms play in an ecosystem, including producers, primary consumers, secondary consumers and decomposers.',
                                        standardsSetToAlignTo: 'CCSS'
                                    })}
                            >
                                {t('Sample')}
                            </Button>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <Typography variant="h3">{t('LessonPlan.title')}</Typography>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <Typography variant="h3"
                                        className="fw-bold text-center poweredbyahura">{t('PoweredByAhuraAI')}</Typography>
                        </div>
                        <div className="col-12 text-center">
                            <Typography variant="caption">
                                {t('LessonPlan.desc')}
                            </Typography>
                        </div>
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('LessonPlan.GradeLevel')}:</Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('LessonPlan.Grade')}</InputLabel>
                                <Select
                                    variant="outlined"
                                    id="demo-simple-select"
                                    value={reqData.gradeLevel!}
                                    label="Age"
                                    onChange={(event: SelectChangeEvent) => setReqData({
                                        ...reqData,
                                        gradeLevel: event.target.value
                                    })}
                                >
                                    <MenuItem value="Elementary">{t('LessonPlan.Elementary')}</MenuItem>
                                    <MenuItem value="Middle School">{t('LessonPlan.MiddleSchool')}</MenuItem>
                                    <MenuItem value="High School">{t('LessonPlan.HighSchool')}</MenuItem>
                                    <MenuItem value="College">{t('LessonPlan.College')}</MenuItem>
                                    <MenuItem value="Grad School">{t('LessonPlan.GradSchool')}</MenuItem>
                                    <MenuItem value="New professional">{t('LessonPlan.Newprofessional')}</MenuItem>
                                    <MenuItem
                                        value="Expert professional">{t('LessonPlan.Expertprofessional')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className=" col-12 mt-3">
                            <div className="col-12 position-relative">
                                <Typography variant="subtitle1">{t('LessonPlan.TopicStandardOrObjective')}:</Typography>
                                <TextField
                                    className="col-12"
                                    placeholder={t('LessonPlan.TopicStandardOrObjectiveDesc')}
                                    multiline
                                    rows={6}
                                    value={reqData.topic}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        topic: event.target.value
                                    })}
                                />
                                <div className='position-absolute right-4 bottom-2'>
                                    <VoiceToSpeechModal setFinalText={(text: any) => setReqData({...reqData, topic: text})}/>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 mt-3">
                            <div className="position-relative">
                                <Typography variant="subtitle1">{t('LessonPlan.AdditionalCriteria')}:</Typography>
                                <TextField
                                    className="col-12"
                                    placeholder={t('LessonPlan.AdditionalCriteriaDesc')}
                                    multiline
                                    rows={4}
                                    value={reqData.additionalCriteria}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        additionalCriteria: event.target.value
                                    })}
                                />
                                <div className='position-absolute row mx-0 right-4 bottom-2'>
                                    <VoiceToSpeechModal setFinalText={(text: any) => setReqData({...reqData, additionalCriteria: text})}/>
                                    <div className="col mx-0 px-0 align-content-center">
                                        <IconButton>
                                            <IoIosAttach/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="col-12 position-relative">
                                <Typography variant="subtitle1">{t('LessonPlan.StandardsSetToAlignTo')}:</Typography>
                                <TextField
                                    className="col-12"
                                    placeholder={t('LessonPlan.StandardsSetToAlignToDesc')}
                                    value={reqData.standardsSetToAlignTo}
                                    onChange={(event) => setReqData({
                                        ...reqData,
                                        standardsSetToAlignTo: event.target.value
                                    })}
                                />
                                <div className='position-absolute right-4 bottom-4'>
                                    <VoiceToSpeechModal setFinalText={(text: any) => setReqData({...reqData, standardsSetToAlignTo: text})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 mt-3 px-0 px-lg-2">
                            <Button className="col-12" variant="contained" color="primary"
                                    onClick={handleGenerateLessonPlans}
                                    disabled={loading}
                            >
                                {loading ? <CircularProgress/> : t('LessonPlan.Generate')}
                            </Button>
                        </div>
                    </div>
                }
                {reqData && prompt.result && (
                    <React.Fragment>
                        <div className={window.innerWidth < 1024 ? "row mx-0 col-12 align-content-start mt-3 p-3" : "row mx-0 col-9 align-content-start mt-3 p-3"}
                             ref={copyContentRef}
                             dangerouslySetInnerHTML={{__html: prompt.result}}
                        />
                        <div className="d-flex gap-3 col-12 text-start">
                            <div>
                                <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<MdContentCopy/>}
                                        onClick={handleCopy}
                                >
                                    {t('Copy')}
                                </Button>
                            </div>
                            <div>
                                <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<TfiExport/>}
                                        onClick={handleExport}>
                                    {t('Export')}
                                </Button>
                            </div>
                            <div>
                                <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<IoIosVolumeHigh/>}
                                        onClick={handleReadAloud}>
                                    {t('ReadAloud')}
                                </Button>
                            </div>

                        </div>
                    </React.Fragment>
                )}
                {loading &&
                    <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5 h-25">
                        <Skeleton className="row mx-0 col-7 mx-auto p-4 mt-5">
                        </Skeleton>
                    </Card>
                }
            </Card>

        </div>
    )
}
