import React, {useState, useEffect, useRef} from 'react';
import {IconButton, CircularProgress} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import {MdSettingsVoice} from "react-icons/md";
import {useAppDispatch} from "../../utils/redux/store";
import {VoiceToTextAPI} from "../../utils/redux/store/promptSlice";

const VoiceToSpeechModal = (props: { setFinalText: React.Dispatch<React.SetStateAction<string>> }) => {
    const dispatch = useAppDispatch()
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<any>(null);
    const [audioURL, setAudioURL] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);

    const chunks = useRef([]);

    useEffect(() => {
        if (audioBlob) {
            const url: any = URL.createObjectURL(audioBlob);
            setAudioURL(url);
            dispatch(VoiceToTextAPI({file: audioBlob})).then((res: any) => {
                props.setFinalText(res.payload.data)
            })
        }
    }, [audioBlob]);


    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            const recorder = new MediaRecorder(stream);

            recorder.ondataavailable = (event: any) => {
                // @ts-ignore
                chunks.current.push(event.data);
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks.current, {type: 'audio/wav'});
                // @ts-ignore
                setAudioBlob(blob);
                chunks.current = [];
            };

            // @ts-ignore
            setMediaRecorder(recorder);
            recorder.start();
            setRecording(true);
        } catch (error) {
            console.error("Error accessing media devices.", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
        }
        setRecording(false);
    };

    return (
        <div className="col mx-0 px-0 d-flex ">
            <div className="col mx-0 px-0 align-content-center">
                <IconButton
                    className={` ${recording ? 'text-danger' : 'text-secondary'}`}
                    onMouseDown={startRecording}
                    onMouseUp={stopRecording}
                    onTouchStart={startRecording}
                    onTouchEnd={stopRecording}
                >
                    {recording ?
                        <CircularProgress size={25}/>
                        :
                        <MdSettingsVoice size={25}/>
                    }
                </IconButton>

            </div>
            {
                audioURL && (
                    <div className="col mx-0 px-0">
                        <audio controls src={audioURL}></audio>
                    </div>
                )
            }
        </div>


    )
        ;
};

export default VoiceToSpeechModal;
