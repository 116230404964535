import React, {useEffect, useState} from "react";
import {Button, Card, CircularProgress, FormControl, InputLabel, Menu, MenuItem, Select, Skeleton, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {MdContentCopy} from "react-icons/md";
import {IoIosVolumeHigh} from "react-icons/io";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../../utils/redux/store";
import {QuestionGeneratorAPI, reset} from "../../../utils/redux/store/promptSlice";
import {useTranslation} from "react-i18next";
import {TfiExport} from "react-icons/tfi";
import {checkValidate} from "../../../utils/redux/store/authSlice";
import {Document, Packer, Paragraph, TextRun} from "docx";
import {stripHtmlTags} from "../../../utils/stripHtmlTags";
import {saveAs} from "file-saver";
import html2pdf from "html2pdf.js";
import VoiceToSpeechModal from "../voiceToSpeechModal";

export const QuestionGenerator = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const copyContentRef = React.useRef(null);
    const prompt = useAppSelector(state => state.prompt.entities);
    const loading: boolean = useAppSelector(state => state.prompt.loading);
    const [files, setFiles] = useState<File[]>([]);
    const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
    const [additionalContext, setAdditionalContext] = useState<string>("");
    const [questionType, setQuestionType] = useState<"multiplechoice" | "openended" | "truefalse">("truefalse");
    const [youtubeUrl, setYoutubeUrl] = useState<string>('');

    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);

    const handleQuestionGenerator = (e: { preventDefault: () => void; }) => {
        if (selectedOption === "Youtube") {
            if (youtubeUrl === "") {
                toast.warning(t("toast.NoYoutubeURL"))
                return
            }
        } else {
            if (files.reduce((accumulator, file) => accumulator + file.size, 0) > 20971520) {
                toast.warning(t("toast.max20MBSize"))
                return
            }

            if (files?.length === 0) {
                toast.warning(t("toast.NoFileUploaded"))
                return
            }
            if (files?.length >= 6) {
                toast.warning(t("toast.Max5PDFAllowed"))
                return
            }
        }
        if (numberOfQuestions === 0) {
            toast.warning(t("toast.numberOfQuestionsMustNotZero"))
            return
        }

        dispatch(QuestionGeneratorAPI({files, numberOfQuestions, additionalContext, questionType, youtubeUrl})).finally(() => {
            dispatch(checkValidate())
        })
    }
    const handleCopy = () => {
        if (copyContentRef.current) {
            const content = (copyContentRef.current as any).innerHTML;

            // Create a temporary contenteditable div to hold the HTML
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.left = '-9999px';
            tempDiv.style.whiteSpace = 'pre';
            tempDiv.contentEditable = 'true';
            tempDiv.innerHTML = content;
            document.body.appendChild(tempDiv);

            // Select and copy the content
            const range = document.createRange();
            range.selectNodeContents(tempDiv);
            const sel: any = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            // Execute the copy command
            document.execCommand('copy');

            // Clean up
            document.body.removeChild(tempDiv);

            toast.success('Content copied to clipboard.');
        }
    }
    const handleExport = () => {
        if (!copyContentRef.current) {
            toast.error('No content to export');
            return;
        }
        const content = copyContentRef.current;
        const opt = {
            margin: 1,
            filename: 'questions.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'A4', orientation: 'portrait'}
        };

        html2pdf().set(opt).from(content).save();


        const docs = new Document({
            sections: [{
                children: [new Paragraph({
                    children: [
                        new TextRun({
                            text: stripHtmlTags((content as any).innerHTML),
                            break: 1
                        })
                    ]
                })]
            }]
        });
        Packer.toBlob(docs).then(blob => {
            saveAs(blob, 'questions.docx');
        });
    }
    const handleReadAloud = () => {
        toast.info("coming soon")
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState<"File" | "Youtube" | "">('');

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option: any) => {
        setSelectedOption(option);
        handleClose();
    };
    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                Back
            </Button>
            <Card variant="elevation" className="row mx-0 col-11 col-lg-7 mx-auto p-4 mt-5">
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3">{t('QuestionGenerator.title')}</Typography>
                </div>
                <div className="col-12 text-center mt-3">
                    <Typography variant="h3" className="fw-bold text-center poweredbyahura">{t('PoweredByAhuraAI')}</Typography>
                </div>
                <div className="col-12 text-center">
                    <Typography variant="caption">{t('QuestionGenerator.Desc')}</Typography>
                </div>
                {selectedOption !== "" &&
                    <div className="col-12 mt-3">
                        <Button className="" variant="contained" color="primary" onClick={() => {
                            setSelectedOption('')
                            dispatch(reset())
                            setYoutubeUrl("")
                            setFiles([] as File[])
                            setAdditionalContext("")
                        }}
                                startIcon={<IoArrowBack/>}
                        >Switch Back</Button>
                    </div>
                }
                {selectedOption === "" ?
                    <div className="col-12 mt-3">
                        <Button variant="contained" onClick={handleClick}>
                            Choose Input Type
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('Youtube')}>
                                Youtube URL
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('File')}>
                                File Upload
                            </MenuItem>
                        </Menu>
                    </div>
                    : selectedOption === "File" ?
                        <div className="col-12 mt-3">
                            <Typography variant="subtitle1">{t('SlideGenerator.SelectFile')}:</Typography>
                            <Button
                                variant="contained"
                                component="label"
                                disabled={loading}
                            >
                                {files.length > 0 ? `${files.length} file(s) selected` : 'Select Files'}
                                <input
                                    type="file"
                                    accept=".pdf"
                                    size={20971520}
                                    hidden
                                    multiple
                                    onChange={(event: any) => setFiles(Array.from(event.target.files))}
                                />
                            </Button>
                        </div>
                        : selectedOption === "Youtube" ?
                            <div className="col-12 mt-3">
                                <Typography variant="subtitle1">{t('SlideGenerator.youtubeUrl')}:</Typography>
                                <TextField
                                    className="col-12"
                                    type="text"
                                    placeholder="Youtube url"
                                    value={youtubeUrl}
                                    onChange={(event) => setYoutubeUrl(event.target.value)}
                                />
                                <div className='position-absolute right-4 bottom-2'>
                                </div>
                            </div>
                            : null
                }
                <div className="col-12 mt-3 position-relative">
                    <Typography variant="subtitle1">{t('QuestionGenerator.numberOfQuestions')}:</Typography>
                    <TextField
                        className="col-12"
                        placeholder={t('LessonPlan.numberOfQuestionsDesc')}
                        type="number"
                        value={numberOfQuestions}
                        onChange={(event) => {
                            if (Number(event.target.value) >= 1 && Number(event.target.value) <= 10) {
                                setNumberOfQuestions(
                                    Number(event.target.value))
                            }

                        }}
                    />
                </div>
                <div className="col-12 mt-3 position-relative">
                    <Typography variant="subtitle1">{t('SlideGenerator.AdditionalContext')}:</Typography>
                    <TextField
                        className="col-12"
                        placeholder={t('SlideGenerator.AdditionalContextDesc')}
                        multiline
                        rows={6}
                        value={additionalContext}
                        onChange={(e) => setAdditionalContext(e.target.value)}
                    />
                    <div className='position-absolute' style={{bottom: '4px', right: '13px'}}>
                        <VoiceToSpeechModal setFinalText={(text: any) => setAdditionalContext(text)}/>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="subtitle1">{t('QuestionGenerator.QuestionType')}:</Typography>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t('QuestionGenerator.QuestionType')}</InputLabel>
                        <Select
                            variant="outlined"
                            id="demo-simple-select"
                            value={questionType}
                            label="Age"
                            onChange={(event: any) => setQuestionType(event.target.value)}
                        >
                            <MenuItem value="openended">open ended</MenuItem>
                            <MenuItem value="truefalse">true/false</MenuItem>
                            <MenuItem value="multiplechoice">multiple choice</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-12 mt-3">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleQuestionGenerator}
                    >
                        {loading ? <CircularProgress size={24}/> : t("QuestionGenerator.button")}
                    </Button>
                </div>
            </Card>
            {prompt.result && (
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5">
                    <div ref={copyContentRef}
                         dangerouslySetInnerHTML={{__html: prompt.result}}
                    />
                    <div className="d-flex gap-3 col-12 text-start">
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<MdContentCopy/>}
                                    onClick={handleCopy}
                            >
                                {t('Copy')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<TfiExport/>}
                                    onClick={handleExport}>
                                {t('Export')}
                            </Button>
                        </div>
                        <div>
                            <Button className="gap-0 rounded-2 border-0 fs-7" startIcon={<IoIosVolumeHigh/>}
                                    onClick={handleReadAloud}>
                                {t('ReadAloud')}
                            </Button>
                        </div>
                    </div>
                </Card>
            )}
            {loading &&
                <Card variant="elevation" className="row mx-0 col-7 mx-auto p-4 mt-5 h-25">
                    <Skeleton className="row mx-0 col-7 mx-auto p-4 mt-5">
                    </Skeleton>
                </Card>
            }

        </div>
    )
}
