import React from 'react';
import {Card, Typography} from "@mui/material";

const LandingBenefitCard = (props: { title: string, description: string, image: string }) => {
    return (
        <Card className="row mx-0 col-12 p-4 h-100" elevation={0}>
            <div className="col-12 col-lg-3 col-xl-2 align-self-center text-center">
                <img className="col-4 col-md-3 col-lg-12" src={props.image} alt="icon"/>
            </div>
            <div className="col-12 col-lg-9 mt-2 mt-md-2 mt-lg-0 mt-xl-0">
                <Typography variant="h5" className="col-12">
                    {props.title}
                </Typography>
                <Typography variant="caption" className="col-12">
                    {props.description}
                </Typography>
            </div>
        </Card>
    );
};
export default LandingBenefitCard;
