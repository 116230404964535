import React from 'react';
import {Grid, Card, CardMedia, CardContent, Typography} from '@mui/material';
import lesson from '../../assets/lesson.webp'
import roleplay from '../../assets/roleplay.webp'
import summerizer from '../../assets/summerizer.webp'

const roles = [
    {
        title: "College Instructors",
        description: "Role play, question extractor, case studies, writing analyzer",
        imgSrc: roleplay // Replace with actual image path
    },
    {
        title: "Parents & High School Instructors",
        description: "Lesson plan, counseling advice, question extractor",
        imgSrc: lesson // Replace with actual image path
    },
    {
        title: "Students",
        description: "Summarize, question extractor, role play, writing analyzer",
        imgSrc: summerizer // Replace with actual image path
    }
];
const Favorites = () => {
    return (
        <Grid container spacing={3} justifyContent="center">
            {roles.map((role, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="100%"
                            image={role.imgSrc}
                            alt={role.title}
                        />
                        <CardContent>
                            <Typography variant="h6" component="div">
                                {role.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {role.description}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default Favorites;
