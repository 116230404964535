import React from "react";
import {Sidebar} from "./sidebar";
import {SidebarHeader} from "./sidebarHeader";

export const Layout = (props: { Component: any }) => {
    return (
        <div className="row mx-0">
            <Sidebar/>
            {window.innerWidth > 1024 && <div className="col-lg-2">
            </div>}
            <div className={window.innerWidth > 1024 ? "row mx-0 px-0 col-12 col-lg-10" : "row mx-0 px-0 col-12 col-lg-12"}>
                <SidebarHeader/>
                <div className="col-12 p-1 p-lg-4">
                    <props.Component/>
                </div>
            </div>
        </div>
    )
}
