import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createAsyncThunk, isFulfilled, isPending} from "@reduxjs/toolkit";
import axios from "axios";
import {IMostUsedTools} from "../model/mostUsedTools.model";

const initialState: EntityState<IAccount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/admin';

export const getMostToolsUsed = createAsyncThunk(
    'admin/fetch_MostToolsUsed',
    async () => {
        const requestUrl = `${apiUrl}/mostToolsUsed`;
        return axios.get<IMostUsedTools>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const adminSlice = createEntitySlice({
    name: 'admin',
    initialState,

    extraReducers: (builder) => {
        builder
            // .addCase(getMostToolsUsed.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(getMostToolsUsed.fulfilled, (state, action) => {
            //     state.entity = action.payload.data;
            //     state.loading = false;
            // })
            // .addCase(getMostToolsUsed.rejected, (state) => {
            //     (state as any).isAuthenticated = false;
            //     state.loading = false;
            // })
            .addMatcher(isFulfilled(getMostToolsUsed), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getMostToolsUsed), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = adminSlice.actions;

export default adminSlice.reducer;
