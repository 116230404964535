// src/store/userSlice.ts
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import axios from "axios";
import {cleanEntity} from "../reducers/entity.utils";
import {IUserChangeLanguage, IUserChangeLanguageResponse} from "../model/user.model";
import {IChargeWallet, IChargeWalletResponse} from "../model/chargeWallet.model";
import {IGetTransactionById, IGetTransactionByIdResponse} from "../model/getTransactionById.model";


const initialState: EntityState<IAccount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/user';


export const changeCurrentLanguageAPI = createAsyncThunk(
    'user/post_changeCurrentLanguage',
    async (entity: IUserChangeLanguage, thunkAPI) => {
        const requestUrl = `${apiUrl}/currentLanguage`;
        const result = axios.post<IUserChangeLanguageResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const setOrganizationIconAPI = createAsyncThunk(
    'user/post_setOrganizationIcon',
    async (entity: {organizationIcon:string}, thunkAPI) => {
        const requestUrl = `${apiUrl}/setOrganizationIcon`;
        const result = axios.post(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const chargeWalletAPI = createAsyncThunk(
    'user/post_chargeWalletAPI',
    async (entity: IChargeWallet, thunkAPI) => {
        const requestUrl = `${apiUrl}/chargeWallet`;
        const result = axios.post<IChargeWalletResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const checkCouponAPI = createAsyncThunk(
    'user/post_checkCouponAPI',
    async (entity: any, thunkAPI) => {
        const requestUrl = `${apiUrl}/checkCoupon`;
        const result = axios.post<IChargeWalletResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const chargeWalletWithDiscountAPI = createAsyncThunk(
    'user/post_chargeWalletWithDiscountAPI',
    async (entity: IChargeWallet, thunkAPI) => {
        const requestUrl = `${apiUrl}/chargeWalletWithDiscount`;
        const result = axios.post<IChargeWalletResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);

export const getTransactionById = createAsyncThunk(
    'user/post_getTransactionById',
    async (entity: IGetTransactionById, thunkAPI) => {
        const requestUrl = `${apiUrl}/getTransactionById`;
        const result = axios.post<IGetTransactionByIdResponse>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);


export const userSlice = createEntitySlice({
    name: 'user',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(checkCouponAPI.pending, (state) => {
                (state as any).loadingcheckCouponAPI = true;
            })
            .addCase(checkCouponAPI.fulfilled, (state, action) => {
                (state as any).entitycheckCouponAPI = action.payload.data;
                (state as any).loadingcheckCouponAPI = false;
            })
            .addCase(checkCouponAPI.rejected, (state) => {
                (state as any).loadingcheckCouponAPI = false;
            })
            .addCase(changeCurrentLanguageAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeCurrentLanguageAPI.fulfilled, (state, action) => {
                state.entity = action.payload.data;
                state.loading = false;
            })
            .addCase(changeCurrentLanguageAPI.rejected, (state) => {
                (state as any).isAuthenticated = false;
                state.loading = false;
            })
            .addCase(chargeWalletAPI.pending, (state) => {
                (state as any).loadingchargeWalletAPI = true;
            })
            .addCase(chargeWalletAPI.fulfilled, (state, action) => {
                (state as any).wallet = action.payload;
                state.loading = false;
            })
            .addCase(chargeWalletAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(chargeWalletWithDiscountAPI.pending, (state) => {
                (state as any).loadingchargeWalletAPI = true;
            })
            .addCase(chargeWalletWithDiscountAPI.fulfilled, (state, action) => {
                (state as any).wallet = action.payload;
                state.loading = false;
            })
            .addCase(chargeWalletWithDiscountAPI.rejected, (state) => {
                state.loading = false;
            })
            .addMatcher(isFulfilled(setOrganizationIconAPI,changeCurrentLanguageAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(setOrganizationIconAPI,changeCurrentLanguageAPI, getTransactionById), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = userSlice.actions;

export default userSlice.reducer;
