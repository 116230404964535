import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Card, CircularProgress, IconButton, TextField, Typography} from "@mui/material";
import {MdSettingsVoice} from "react-icons/md";
import {t} from "i18next";
import Button from "@mui/material/Button";
import {useAppDispatch, useAppSelector} from "../../../../utils/redux/store";
import {
    getRoomDetailByRoomIdAPI,
    JoinTheRoomByRoomIdAPI
} from "../../../../utils/redux/store/rolePlaySlice";
import {IRolePlay} from "../../../../utils/redux/model/roleplay.model";
import {toast} from "react-toastify";

const RolePlayRoom = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {roomGuid} = useParams()
    const roomData: IRolePlay = useAppSelector(state => state.roleplay.entity)
    const loading: boolean = useAppSelector(state => state.roleplay.loading)
    const loadingGetRoomDetailByRoomIdAPI: boolean = useAppSelector(state => state.roleplay.getRoomDetailByRoomIdAPIError)
    const [reqData, setReqData] = useState({
        email: "",
        name: "",
        room_guid: roomGuid
    })
    const handleJoinGuest = () => {
        if (reqData.name == "" ) {
            toast.warning("Please fill the form.")
            return
        }
        dispatch(JoinTheRoomByRoomIdAPI({email: reqData.email, name: reqData.name, room_guid: roomGuid!}))
            .then((req: any) => {
                if (req.payload) {
                    navigate("/tools/roleplay-generator/room/" + roomGuid + "/" + req.payload.data.id)
                    localStorage.setItem("guestId", String(req.payload.data.id))
                }
            })
    }
    useEffect(() => {
        dispatch(getRoomDetailByRoomIdAPI({guid: roomGuid!}))
    }, [])
    if (loading) {
        return <CircularProgress/>
    }
    if (loadingGetRoomDetailByRoomIdAPI) {
        window.location.href = "/"
        return <CircularProgress/>
    }

    return (
        <div className="row col-12 col-lg-6 p-3 mx-auto mt-5 rounded">
            <Card className="row col-8 p-3 py-4 mx-auto mt-5 rounded">
                <div className="col-12 mt-2 text-center">
                    <Typography variant="h3">
                        Join The Room
                    </Typography>
                </div>

                <div className="col-12 mt-5">
                    <Typography variant="h5">
                        {roomData.room_name}
                    </Typography>
                </div>
                <div className="col-12 mt-3">
                    <Typography variant="caption">
                        {roomData.scenario}
                    </Typography>
                </div>
                <div className="col-12 mt-3">
                    <div className="d-flex">
                        <Typography variant="subtitle1">{t('RolePlayRoom.name')}:</Typography>
                        <Typography className="fs-7" variant="caption">{t('Required')}</Typography>
                    </div>
                    <TextField
                        className="col-12"
                        placeholder={t('RolePlayRoom.nameDesc')}
                        value={reqData.name}
                        required
                        onChange={(e) => setReqData({...reqData, name: e.target.value})}
                    />
                    <div className='position-absolute right-4 bottom-2'>
                        <IconButton className="col-12">
                            <MdSettingsVoice/>
                        </IconButton>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    <Typography variant="subtitle1">{t('RolePlayRoom.email')}:</Typography>
                    <TextField
                        className="col-12"
                        placeholder={t('RolePlayRoom.emailDesc')}
                        value={reqData.email}
                        onChange={(e) => setReqData({...reqData, email: e.target.value})}
                    />
                    <div className='position-absolute right-4 bottom-2'>
                        <IconButton className="col-12">
                            <MdSettingsVoice/>
                        </IconButton>
                    </div>
                </div>
                <div className="row mx-0 col-12 mt-3">
                    <Button variant="contained" color="primary" className="col-12"
                            onClick={handleJoinGuest}
                    >
                        Join
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default RolePlayRoom;
