import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route} from 'react-router-dom';
import {PageNotFound} from "./components/shared/PageNotFound";
import {Login} from "./components/auth/login";
import {Layout} from "./components/shared/Layout";
import {Tools} from "./components/tools/tools";
import {Register} from "./components/auth/register";
import {useAppDispatch, useAppSelector} from "./utils/redux/store";
import {checkValidate} from "./utils/redux/store/authSlice";
import ErrorBoundaryRoutes from "./utils/error/ErrorBoundaryRoutes";
import {getToken} from "./utils/localstorage/localStorage";
import {LessonPlanGenerator} from "./components/tools/lesson-plan-generator/LessonPlanGenerator";
import {MostToolsUsed} from "./components/admin/mostToolsUsed";
import {ActivityIdeaGenerator} from "./components/tools/activity-idea-generator/ActivityIdeaGenerator";
import {CounselingGenerator} from "./components/tools/counseling-generator/CounselingGenerator";
import {UnderConstruction} from "./components/shared/underConstruction";
import {ChargeWallet} from "./components/user/ChargeWallet";
import {TransformationContent} from "./components/tools/transform-content-generator/TransformationContent";
import {SlideGenerator} from "./components/tools/slide-generator/SlideGenerator";
import {QuestionGenerator} from "./components/tools/slide-generator/QuestionGenerator";
import {InviteTeachers} from "./components/organization/InviteTeachers";
import PaymentFailed from "./components/payment/paymentfailed";
import PaymentSuccess from "./components/payment/paymentSuccess";
import AhuraUserSSO from "./components/auth/ahura-user-sso";
import {OrganizationSetting} from "./components/user/OrganizationSetting";
import RolePlay from "./components/tools/role-play/rolePlay";
import RolePlayRoom from "./components/tools/role-play/room/roleplay-room";
import RoleplayGuestsList from "./components/tools/role-play/room/roleplay-guestslist";
import RoleplayRoomChat from "./components/tools/role-play/room/roleplay-room-chat";
import B2BComponent from "./components/b2b/b2b";
import CouponManagement from "./components/admin/couponManagment";
import SubscriptionPlanManagement from "./components/admin/subscriptionPlanManagment";
import SubscriptionPlan from "./components/user/SubscriptionPlan/SubscriptionPlan";
import VerifyEmail from "./components/auth/verify-email";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/reset-password";
import Landing from "./components/landing/landing";
import InviteeVerify from "./components/organization/invitee/inviteeVerify";
import Favorites from "./components/favorites/favorites";

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
    if (authorities && authorities.length !== 0) {
        if (hasAnyAuthorities.length === 0) {
            return true;
        }
        return hasAnyAuthorities.some(auth => authorities.includes(auth));
    }
    return false;
};

function AppRoutes() {
    const dispatch = useAppDispatch()
    const isAuthenticated: boolean = useAppSelector(state => state.auth.isAuthenticated);
    const isAdmin = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.ADMIN]));
    const isUser = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.USER]));
    const isOrganization = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.ORGANIZATION]));
    const isInvitee = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.INVITEE]));
    useEffect(() => {
        if (getToken() !== null) {
            dispatch(checkValidate())
        }
    })

    return (
        <BrowserRouter>
            <ErrorBoundaryRoutes>
                {isAuthenticated ?
                    isUser || isOrganization || isAdmin || isInvitee ?
                        <React.Fragment>
                            <Route path="/" element={<Layout Component={Tools}/>}/>
                            <Route path="/tools" element={<Layout Component={Tools}/>}/>
                            <Route path="/Favorites" element={<Layout Component={Favorites}/>}/>
                            <Route path="/b2b" element={<B2BComponent/>}/>
                            <Route path="/login" element={
                                <Navigate
                                    to={{
                                        pathname: '/',
                                    }}
                                    replace
                                />
                            }/>
                            <Route path="/Payment/success/:transactionId" element={<PaymentSuccess/>}/>
                            <Route path="/Payment/cancel/:transactionId" element={<PaymentFailed/>}/>
                            <Route path="/tools/lesson-plan-generator"
                                   element={<Layout Component={LessonPlanGenerator}/>}/>
                            <Route path="/tools/activity-ideas-generator"
                                   element={<Layout Component={ActivityIdeaGenerator}/>}/>
                            <Route path="/tools/counseling-generator"
                                   element={<Layout Component={CounselingGenerator}/>}/>
                            {/*<Route path="/tools/pdf-Summarizer"*/}
                            {/*       element={<Layout Component={PDFSummarizer}/>}/>*/}
                            <Route path="/tools/transform-content-generator"
                                   element={<Layout Component={TransformationContent}/>}/>
                            <Route path="/tools/slide-generator"
                                   element={<Layout Component={SlideGenerator}/>}/>
                            <Route path="/tools/question-generator"
                                   element={<Layout Component={QuestionGenerator}/>}/>
                            <Route path="/tools/roleplay-generator"
                                   element={<Layout Component={RolePlay}/>}/>
                            <Route path="/tools/roleplay-generator/room/:roomGuid"
                                   element={<RolePlayRoom/>}/>
                            <Route path="/tools/roleplay-generator/room/:roomGuid/:guestId"
                                   element={<RoleplayRoomChat/>}/>
                            <Route path="/tools/roleplay-generator/guestList/:roomGuid"
                                   element={<Layout Component={RoleplayGuestsList}/>}/>
                            <Route path="/tools/teacher-bot"
                                   element={<Layout Component={UnderConstruction}/>}/>
                            <Route path="/tools/quick-course-bot"
                                   element={<Layout Component={UnderConstruction}/>}/>
                            <Route path="/user/chargeWallet"
                                   element={<Layout Component={ChargeWallet}/>}/>
                            <Route path="/user/subscriptionPlan"
                                   element={<Layout Component={SubscriptionPlan}/>}/>
                            {isUser && <Route path="/setting"
                                // element={<Layout Component={UserSetting}/>}/>
                                              element={<Layout Component={UnderConstruction}/>}/>
                            }
                            {isOrganization &&
                                <React.Fragment>
                                    <Route path="/setting"
                                           element={<Layout Component={OrganizationSetting}/>}/>
                                    {/*element={<Layout Component={UnderConstruction}/>}/>*/}
                                    <Route path="/inviteTeachers"
                                        // element={<Layout Component={OrganizationSetting}/>}/>
                                           element={<Layout Component={InviteTeachers}/>}/>

                                </React.Fragment>
                            }
                            {isInvitee &&
                                <React.Fragment>
                                    <Route path="/inviteeSetting"
                                        // element={<Layout Component={InviteeSetting}/>}/>
                                           element={<Layout Component={UnderConstruction}/>}/>


                                </React.Fragment>
                            }
                            {isAdmin &&
                                <React.Fragment>
                                    <Route path="/admin/mostToolsUsed" element={<Layout Component={MostToolsUsed}/>}/>
                                    <Route path="/admin/CouponManagement"
                                           element={<Layout Component={CouponManagement}/>}/>
                                    <Route path="/admin/SubscriptionPlanManagement"
                                           element={<Layout Component={SubscriptionPlanManagement}/>}/>
                                    <Route path="/AdminSetting"
                                           element={<Layout Component={UnderConstruction}/>}/>
                                </React.Fragment>
                            }

                            <Route path='*' element={<PageNotFound/>}/>
                        </React.Fragment>
                        :
                        <Route path="/" element={
                            <div className="insufficient-authority">
                                <div className="alert alert-danger">You are not authorized to access this page.
                                </div>
                            </div>
                        }/>
                    :
                    <React.Fragment>
                        <Route path="/" element={<Landing/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/verifyEmail/:guid" element={<VerifyEmail/>}/>
                        <Route path="/b2b" element={<B2BComponent/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/forget-password" element={<ForgetPassword/>}/>
                        <Route path="/reset-password/:guid" element={<ResetPassword/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/ahuraUserSSO/:email/:guid" element={<AhuraUserSSO/>}/>
                        <Route path="/Payment/success/:transactionId" element={<PaymentSuccess/>}/>
                        <Route path="/Payment/cancel/:transactionId" element={<PaymentFailed/>}/>
                        <Route path="/tools/roleplay-generator/room/:roomGuid"
                               element={<RolePlayRoom/>}/>
                        <Route path="/invitee/verify/:inviteeEmailToken"
                               element={<InviteeVerify/>}/>
                        <Route path="/tools/roleplay-generator/room/:roomGuid/:guestId"
                               element={<RoleplayRoomChat/>}/>
                        <Route path='*' element={
                            <Navigate
                                to={{
                                    pathname: '/login',
                                }}
                                replace
                            />
                        }
                        />
                    </React.Fragment>
                }

            </ErrorBoundaryRoutes>
        </BrowserRouter>
    );
}


export default AppRoutes;

export const AUTHORITIES = {
    ADMIN: 'admin',
    USER: 'user',
    ORGANIZATION: 'organization',
    INVITEE: 'invitee',
};
