// src/store/promptSlice.ts
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';
import {EntityState} from "../../../components/shared/interface/interfaces";
import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import axios from "axios";
import {defaultValue, IDiscount} from "../model/discount.model";
import {cleanEntity} from "../reducers/entity.utils";


const initialState: EntityState<IDiscount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/admin';


export const getEntities = createAsyncThunk(
    'discount/fetch_all',
    async ({limit = 10, offset = 0}: { limit?: number; offset?: number }) => {
        const requestUrl = `${apiUrl}/getListOfCoupons?limit=${limit}&offset=${offset}`;
        return axios.get<IDiscount[]>(requestUrl);
    },
    {serializeError: serializeAxiosError}
);

export const createCouponAPI = createAsyncThunk(
    'prompts/post_createCouponAPI',
    async (entity: IDiscount, thunkAPI) => {
        const requestUrl = `${apiUrl}/createCoupon`;
        const result = axios.post<IDiscount>(requestUrl, cleanEntity(entity));
        return result
    },
    {serializeError: serializeAxiosError,},
);
export const discountSlice = createEntitySlice({
    name: 'discount',
    initialState,

    extraReducers: (builder) => {
        builder
            // .addCase(lessonPlanAPI.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(lessonPlanAPI.fulfilled, (state, action) => {
            //     state.entity = action.payload.data;
            //     state.loading = false;
            // })
            // .addCase(lessonPlanAPI.rejected, (state) => {
            //     state.loading = false;
            // })

            .addMatcher(isFulfilled(getEntities), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = discountSlice.actions;

export default discountSlice.reducer;
