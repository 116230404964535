import React, {useState} from 'react';
import {Box, Button, Card,  Modal,TextField, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {useAppDispatch} from "../../../utils/redux/store";
import {toast} from "react-toastify";
import {acceptInviteToOrganizationAPI, rejectInviteToOrganizationAPI} from "../../../utils/redux/store/organizationSlice";

const InviteeVerify = () => {
    const dispatch = useAppDispatch()
    const {inviteeEmailToken} = useParams()
    const [isOpen, setIsOpen] = useState(false)
    const [password, setPassword] = useState("")
    const handleAcceptInvitation = () => {
        if (password === "") {
            toast.warning("please fill the password.")
            return
        }
        dispatch(acceptInviteToOrganizationAPI({email_token: inviteeEmailToken!, password: password}))
            .finally(() => {
                setIsOpen(false)
                setTimeout(() => {
                    window.location.href = '/login'
                }, 3000)

            })

    }
    const handleRejectInvitation = () => {
        dispatch(rejectInviteToOrganizationAPI({email_token: inviteeEmailToken!}))
    }
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 0,
        p: 4,
    };
    if (!inviteeEmailToken) {
        toast.warning("invalid token.")
        window.location.href = '/login'
    }
    return (
        <div className="row mx-0 col-12 mt-5">
            <Card className="col-12 col-lg-5 p-3 mx-auto text-center rounded-3 border-0 shadow-none mt-5">
                <Typography variant="h3">You've been invited to join an organization. Would you like to accept?</Typography>
                <div className="col-12  align-items-center mt-3">
                    <Button className="w-167 m-2" variant="contained" color="primary" onClick={() => setIsOpen(true)}>Accept</Button>
                    <Button component={Link} to="/login" className="w-167 hi-50 m-2" variant="contained" color="secondary"
                            onClick={handleRejectInvitation}>Reject</Button>
                </div>
            </Card>
            <Modal open={isOpen}
                   onClose={() => setIsOpen(false)}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <Box sx={style} className="rounded-2 border-0">
                    <div>Please set a password for your account</div>
                    <div className="col-12 mt-3">
                        <TextField
                            className="col-12"
                            placeholder={"Password"}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </div>
                    <div className="col-12 text-center mt-3">
                        <Button className="w-167 m-2" variant="contained" color="primary" onClick={handleAcceptInvitation}>Confirm</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default InviteeVerify;
