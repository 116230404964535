import React, {useState} from 'react';
import {Button, CircularProgress, Modal, TextField, Typography} from "@mui/material";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {t} from "i18next";
import {useDispatch} from "react-redux";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {buyBulkSubscriptionAPI} from "../../utils/redux/store/organizationSlice";
import {toast} from "react-toastify";

const BuyBulkSubscriptionModal = () => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false)
    const [reqData, setReqData] = useState({basic: 0, premium: 0})
    const paymentUrl: string = useAppSelector(state => state.organization.paymentUrl);
    const loading: boolean = useAppSelector(state => state.organization.loading);

    const handleBuyBulkSubscriptions = () => {
        if (reqData.basic == 0 && reqData.premium == 0) {
            toast.warning("Please add at least one basic/premium subscription.")
            return
        }
        dispatch(buyBulkSubscriptionAPI(reqData))
            .then((res) => {
                const response = (res.payload as any).data
                if ((res.payload as any).status === 200) {
                    if (typeof response === "string") {
                        window.location.href = response
                    }
                }
            })
    }
    return (
        <div>
            <Button variant="contained" color='primary' className="gap-0 mt-4 px-3"
                    startIcon={<AiOutlineShoppingCart/>}
                // disabled={loading}
                    onClick={() => setIsOpen(true)}
            >
                Buy bulk subscriptions
            </Button>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <div className="row col-5 bg-white border-0 rounded-2 p-3 mx-auto mt-5">
                    <div className="col-12">
                        <Typography className="text-center" variant="h5">How many subscription licenses would you like to purchase?</Typography>
                    </div>
                    <div className="col-6">
                        <Typography className="text-center" variant="caption">Basic</Typography>
                        <TextField
                            className="col-12"
                            placeholder="Basic"
                            type="number"
                            value={reqData.basic}
                            onChange={(e) => setReqData({...reqData, basic: Number(e.target.value)})}
                        />
                    </div>
                    <div className="col-6">
                        <Typography className="text-center" variant="caption">Premium</Typography>
                        <TextField
                            className="col-12"
                            placeholder="Premium"
                            type="number"
                            value={reqData.premium}
                            onChange={(e) => setReqData({...reqData, premium: Number(e.target.value)})}
                        />
                    </div>
                    <div className="col-12 d-flex gap-2 mx-0 mt-4" dir="rtl">
                        <Button
                            variant="contained"
                            startIcon={<AiOutlineShoppingCart/>}
                            dir="ltr"
                            onClick={handleBuyBulkSubscriptions}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress/> : "Buy subscription"}
                        </Button>
                        <Button variant="text" color="error" onClick={() => setIsOpen(false)}>Close</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BuyBulkSubscriptionModal;
