import React from 'react';
import {Button, Typography} from "@mui/material";
import underConstructionLogo from "../../../src/assets/underconstructionlogo.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";

export const UnderConstruction = () => {
    const {t} = useTranslation();
    return (
        <div className="row mx-0">
            {/* @ts-ignore*/}
            <Button
                LinkComponent={Link}
                to="/tools"
                variant="contained"
                color="secondary"
                className="col-3 col-md-2 col-lg-2 col-xl-1 position-absolute"
                startIcon={<IoArrowBack/>}
            >
                {t('Back')}
            </Button>
            <div className="col-12 text-center mt-5">
                <img className="col-5" src={underConstructionLogo} alt="underConstructionLogo"/>
            </div>
            <div className="col-12 text-center">
                <Typography variant="h1" component="h1">
                    {t('UnderConstruction.ComingSoon')}
                </Typography>
            </div>

            <div className="col-12 text-center mt-5">
                <Typography variant="h3" component="h2">
                    {t('UnderConstruction.Desc')}
                </Typography>
            </div>
        </div>
    )
}
